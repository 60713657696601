import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';
import * as CD from './_m_delete';

export const ManagerType = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [selected, setSelected] = React.useState<any>('');
  const [data, setData] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      onOK();
    },
  });

  const refresh = () => {
    appCtx.fetchApi2('get', '/api/v2/fridge/device-type').then((res) => {
      setData(
        res.fridges.map((item: any) => ({
          title: item.title,
          value: item.id,
        })),
      );
    });
  };

  React.useEffect(() => {
    refresh();
  }, []);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('分類管理')}</div>

      <div className="p-3">
        <div className="mb-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={() => {
              appCtx.setModal2(
                <NewEditCopy1_new
                  onOK={() => {
                    refresh();
                    appCtx.setModal2(null);
                  }}
                  onCancel={() => appCtx.setModal2(null)}
                />,
              );
            }}
          >
            <div className="text-white text-base">{'新增'}</div>
          </button>
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={() => {
              appCtx.setModal2(
                <NewEditCopy2_edit
                  item={{ id: selected }}
                  onOK={() => {
                    refresh();
                    appCtx.setModal2(null);
                  }}
                  onCancel={() => appCtx.setModal2(null)}
                />,
              );
            }}
          >
            <div className="text-white text-base">{'修改'}</div>
          </button>
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={() => {
              appCtx.setModal2(
                <CD.DeleteMsgBox
                  onOK={async () => {
                    await appCtx.fetchApi2(
                      'delete',
                      `/api/v2/fridge/device-type?id=${selected}`,
                    );
                    refresh();
                    appCtx.setModal2(null);
                  }}
                  onCancel={() => appCtx.setModal2(null)}
                />,
              );
            }}
          >
            <div className="text-white text-base">{'刪除'}</div>
          </button>
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Save')}</div>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-3">
          <div>
            <div className="font-bold">使用冰櫃類型</div>
            <C.SelectableList
              value={selected}
              onValueChange={setSelected}
              data={data}
            />
          </div>

          <div>
            <div className="font-bold">冰櫃照片</div>
            <C.SelectableList value="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const NewEditCopy1_new = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi2('post', '/api/v2/fridge/device-type', {
        title: values.v1,
      });
      onOK();
    },
  });

  React.useEffect(() => {}, []);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('新增')}</div>

      <div className="p-3">
        <div className="grid grid-cols-1 gap-3">
          <F.Input
            label="名稱"
            value={formik.values.v1}
            onValueChange={(value) => formik.setFieldValue('v1', value)}
          />
        </div>

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewEditCopy2_edit = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi2('patch', '/api/v2/fridge/device-type', {
        id: item.id,
        title: values.v1,
      });
      onOK();
    },
  });

  React.useEffect(() => {}, []);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('修改')}</div>

      <div className="p-3">
        <div className="grid grid-cols-ㄅ gap-3">
          <F.Input
            label="名稱"
            value={formik.values.v1}
            onValueChange={(value) => formik.setFieldValue('v1', value)}
          />
        </div>

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
