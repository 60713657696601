import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any>({});

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi2('get', '/api/v2/about').then((result) => {
        if (result) {
          // alert(JSON.stringify(result));
          setData(result);
        }
      });
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'關於'} />

      <C.ContentPanel>
        {data?.sections?.map((section: any) => {
          return (
            <div className="mb-10">
              <div
                className="text-3xl font-bold mb-3"
                style={{ color: '#6890B1' }}
              >
                {section.title}
              </div>

              <div>{section.content}</div>
            </div>
          );
        })}
      </C.ContentPanel>
    </div>
  );
};
