import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [err, setErr] = React.useState<string>('');

  const formik = useFormik({
    initialValues: {
      // account: 'devt@gmail.com',
      // password: 'devt123',
      account: 'jango@gmail.com',
      password: 'qwert123',
    },
    onSubmit: async (values) => {
      appCtx
        .login(values.account, values.password)
        .then((result) => {
          if (result) {
            history.push('/');
          }
        })
        .catch((err) => {
          setErr(err.message);
        });
    },
  });

  return (
    <div className="h-screen flex flex-col">
      <C.HeaderUnauthed title={appCtx.config.title} />

      <div className="w-full md:w-1/4 mx-auto px-5 flex flex-col items-stretch">
        <div className="h-20" />

        <div className="flex items-center mb-5">
          <div className="text-base mr-3">{t('Account')}</div>
          <C.TextInput
            className="flex-1"
            placeholder="ID"
            value={formik.values.account}
            onValueChange={(value) => formik.setFieldValue('account', value)}
          />
        </div>

        <div className="flex items-center mb-10">
          <div className="text-base mr-3">{t('Password')}</div>
          <C.TextInput
            className="flex-1"
            placeholder="PASSWORD"
            value={formik.values.password}
            onValueChange={(value) => formik.setFieldValue('password', value)}
            type="password"
          />
        </div>

        <C.Button title={t('Login')} onClick={() => formik.submitForm()} />
      </div>

      <div className="mt-20 mx-auto text-base text-red-500">{err}</div>

      <div className="flex-1" />

      <div className="mx-auto mb-2">
        <div className="flex items-center">
          <img src={appCtx.config.vendor_icon} />

          <div className="ml-2 text-base">{appCtx.config.vendor_title}</div>
        </div>
      </div>
    </div>
  );
};
