import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

// import { SelectPanel } from './_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [pageInfo, setPageInfo] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',

      vendor: '',

      durationDate: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      // store: Yup.string().required(t('Required')),
      // vendor: Yup.string().required(t('Required')),
      durationDate: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      const start = moment(values.durationDate).startOf('day');
      const end = moment(values.durationDate).add(7, 'days').endOf('day');

      const fstart = appCtx.timeFormat(start);
      const fend = appCtx.timeFormat(end);

      appCtx
        .fetchApi(
          'get',
          `/api/v2/iot-connection/detail?depart=${values.department}&storeid=${values.store}&vendor=${values.vendor}&start=${fstart}&end=${fend}&timezone=${appCtx.getTimezone}`,
        )
        .then((result) => {
          if (result) {
            setData(result);
          }
        });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx
        .fetchApi('get', '/api/v2/iot-connection/detail/pageinfo')
        .then((result) => {
          if (result) {
            setPageInfo(result);
            formik.setFieldValue('durationDate', result.default_date);
          }
        });
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Device connection rate')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          {/* <SelectPanel
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
            useVendor={true}
            selectedVendor={formik.values.vendor}
            setSelectedVendor={(value) => formik.setFieldValue('vendor', value)}
          /> */}
          <C.BlueLabel title={t('Select')} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="border-b border-gray-300 h-1" />
          <SelectVendor
            depart={formik.values.department}
            selectedVendor={formik.values.vendor}
            setSelectedVendor={(value) => formik.setFieldValue('vendor', value)}
          />

          <div className="h-3" />

          <C.BlueLabel title={pageInfo?.duration_title} />
          <C.DatePicker
            value={formik.values.durationDate}
            onValueChange={(value) =>
              formik.setFieldValue('durationDate', value)
            }
          />
          <div className="h-3" />

          <C.Button
            title={t('Inquire')}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
          <C.Button
            title={pageInfo?.pre_title}
            onClick={() => {
              const t =
                new Date(formik.values.durationDate).getTime() -
                pageInfo.duration * 24 * 60 * 60 * 1000;
              const newD = moment.utc(t).format('YYYY-MM-DD');

              formik.setFieldValue('durationDate', newD);
            }}
            disabled={!formik.isValid}
          />
          <C.Button
            title={pageInfo?.next_title}
            onClick={() => {
              const t =
                new Date(formik.values.durationDate).getTime() +
                pageInfo.duration * 24 * 60 * 60 * 1000;
              const newD = moment.utc(t).format('YYYY-MM-DD');

              formik.setFieldValue('durationDate', newD);
            }}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel fixedHeight>
          {data && (
            <ReflexContainer orientation="horizontal">
              <ReflexElement flex={2}>
                <div className="flex mb-3">
                  <C.BlueLabel title={t('Statistic chart')} />
                </div>

                <div className="mb-3 grid grid-cols-1 md:grid-cols-3 gap-10">
                  {data?.charts?.map((item: any) => (
                    <C.ChartEx chart={item} />
                  ))}
                  {data?.charts?.length === 0 && (
                    <div className="w-full flex justify-center py-5">
                      <div className="text-green-600">無資料</div>
                    </div>
                  )}
                </div>
                {data?.charts?.length === 0 && (
                  <div className="text-center my-10">{t('No data')}</div>
                )}
                <div className="h-10" />
              </ReflexElement>

              {/* splitter */}
              <ReflexSplitter
                className="my-5"
                style={{
                  backgroundColor: 'rgb(104,144,177)',
                  border: 'none',
                  height: 1,
                }}
              />

              <ReflexElement flex={1}>
                <C.Table2
                  label={data?.table?.title}
                  header={data?.table?.header}
                  data={data?.table?.data}
                  page_size={data?.table?.page_size}
                  filter_index={data?.table?.filter_index}
                />
              </ReflexElement>
            </ReflexContainer>
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
