import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';

import clsx from 'clsx';

export const NewT1 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [option1, setOption1] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', '/api/v2/util/auth/page-auth-type')
      .then((result) => {
        if (result) {
          setOption1(
            result.auth_type.map((item: any) => ({
              title: item[0],
              value: item[0],
            })),
          );
        }
      });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: false,
      v4: false,
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.boolean(),
      v4: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/util/auth/webpage', {
        data: [[values.v1, values.v2, [values.v3, values.v4]]],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Input
          label={t('Account')}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="權限"
          options={option1}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Checkbox
          label={t('Device connection rate')}
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Checkbox
          label="異常發生記錄"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewT2 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [option1, setOption1] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', '/api/v2/util/auth/device-auth-type')
      .then((result) => {
        if (result) {
          setOption1(
            result.auth_type.map((item: any) => ({
              title: item[0],
              value: item[0],
            })),
          );
        }
      });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: false,
      v4: false,
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.boolean(),
      v4: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('delete', '/api/v2/util/auth/device', {
        data: [[values.v1, values.v2, [values.v3, values.v4]]],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Input
          label={t('Account')}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="權限"
          options={option1}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Checkbox
          label="WI"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Checkbox
          label="4度C"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT1 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [option1, setOption1] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', '/api/v2/util/auth/page-auth-type')
      .then((result) => {
        if (result) {
          setOption1(
            result.auth_type.map((item: any) => ({
              title: item[0],
              value: item[0],
            })),
          );
        }
      });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.boolean(),
      v4: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/util/auth/webpage', {
        data: [
          { id: item.id, data: [values.v1, values.v2, [values.v3, values.v4]] },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Input
          label={t('Account')}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="權限"
          options={option1}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Checkbox
          label={t('Device connection rate')}
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Checkbox
          label="異常發生記錄"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT2 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [option1, setOption1] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', '/api/v2/util/auth/device-auth-type')
      .then((result) => {
        if (result) {
          setOption1(
            result.auth_type.map((item: any) => ({
              title: item[0],
              value: item[0],
            })),
          );
        }
      });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.boolean(),
      v4: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/util/auth/device', {
        data: [
          { id: item.id, data: [values.v1, values.v2, [values.v3, values.v4]] },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Input
          label={t('Account')}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="權限"
          options={option1}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Checkbox
          label="WI"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Checkbox
          label="4度C"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
