import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FiSearch } from 'react-icons/fi';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any>({});
  const [selectedCategory, setSelectedCategory] = React.useState<string>('');
  const [text, setText] = React.useState<string>('');

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi2('get', '/api/v2/faq').then((result) => {
        if (result) {
          setData(result);
        }
      });
    }
  }, [appCtx.token]);

  let array = [];
  if (data) {
    for (let i = 0; i < Object.values(data).length; i++) {
      const a: any = Object.values(data)[i];

      for (let j = 0; j < Object.values(a).length; j++) {
        const b: any = Object.values(a)[j];

        array.push(b);
      }
    }
  }

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'FAQ'} />

      <div className="flex flex-col justify-center items-center py-10 bg-gray-200">
        <div className="text-5xl font-bold">常見問與答FAQ</div>

        <div className="flex items-center mt-5">
          <div className="bg-white px-2 py-2">
            <FiSearch />
          </div>
          <input
            className="bg-white py-1 px-2 outline-none"
            placeholder="搜尋支援"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button className="bg-button text-white py-1 px-3" onClick={() => {}}>
            搜尋
          </button>
        </div>
      </div>

      <div className="flex mt-5">
        {text === '' && (
          <div className="w-full md:w-96 bg-gray-300 text-center py-10 h-screen">
            <div className="text-2xl font-bold">FAQ</div>
            <div className="border-b-2 border-gray-800 my-5 mx-5" />

            {Object.keys(data).map((key) => {
              return (
                <div
                  className="text-2xl mb-5 cursor-pointer"
                  onClick={() => setSelectedCategory(key)}
                >
                  {key}
                </div>
              );
            })}
          </div>
        )}

        {text === '' && (
          <div className="flex-1 bg-gray-200 p-5 h-screen">
            {data &&
              data[selectedCategory] &&
              Object.values(data[selectedCategory]).map((item: any, index) => {
                return (
                  <details className="mb-5">
                    <summary className="p-3 bg-gray-300 cursor-pointer">
                      <span className="text-blue-700 mr-5">{`問題${
                        index + 1
                      }`}</span>
                      <span>{item.title}</span>
                    </summary>
                    <div className="pl-20 py-5">{item.content}</div>
                  </details>
                );
              })}
          </div>
        )}

        {text !== '' && (
          <div className="flex-1 bg-gray-200 p-5 h-screen">
            {data &&
              array
                .filter((item) => {
                  return (
                    item.title.includes(text) || item.content.includes(text)
                  );
                })
                .map((item: any, index) => {
                  return (
                    <details className="mb-5">
                      <summary className="p-3 bg-gray-300 cursor-pointer">
                        <span className="text-blue-700 mr-5">{`問題${
                          index + 1
                        }`}</span>
                        <span>{item.title}</span>
                      </summary>
                      <div className="pl-20 py-5">{item.content}</div>
                    </details>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
};
