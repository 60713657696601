import React from 'react';
import clsx from 'clsx';

export interface SelectOption {
  title: string;
  value: string;
}

export interface SelectProps {
  className?: string;
  options: SelectOption[];
  value?: string;
  onValueChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
}

export const Select = ({
  className,
  value,
  onValueChange,
  label,
  placeholder,
  options,
}: SelectProps) => {
  return (
    <div>
      <label className="text-gray-500">{label}</label>

      <div className="w-full h-10 mt-2 px-3 bg-gray-200 text-gray-500 rounded-lg">
        <select
          className={clsx(
            'w-full h-10 bg-gray-200 text-gray-500 rounded-lg',
            className,
          )}
          value={value}
          onChange={(evt) => onValueChange && onValueChange(evt.target.value)}
          placeholder={placeholder}
        >
          <option value="" disabled selected hidden>
            {placeholder}
          </option>
          {options?.map((item, index) => (
            <option key={index.toString()} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
