import React from 'react';
import { RingProgress as AntdRingProgress } from '@ant-design/charts';

export interface RingProgressProps {
  title: string;
  percent: number;
}

export const RingProgress = ({ title, percent }: RingProgressProps) => {
  var config = {
    height: 200,
    width: 100,
    autoFit: false,
    percent,
    color: ['#6890B1', '#E8EDF3'],
    innerRadius: 0.75,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          lineHeight: '14px',
        },
        formatter: function formatter() {
          return '比例';
        },
      },
    },
  };
  return (
    <div className="flex flex-col justify-center items-center space-y-5">
      <AntdRingProgress {...config} />
      <div className="text-base">{title}</div>
    </div>
  );
};
