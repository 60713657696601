import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
      storeQuick: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
      storeQuick: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi(
          'get',
          `/api/v2/util/lost-alarm/cfg?section=a&depart=b&store=c`,
        )
        .then((result) => {
          if (result) {
          }
        });
    },
  });

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'線上檢修-傳訊博士'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇門市'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <div className="flex space-x-3">
            <C.Button className="flex-1" title="組織管理" onClick={() => {}} />
            <C.Button className="flex-1" title="搜尋" onClick={() => {}} />
          </div>
          <div className="h-3" />

          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />

          <SelectStoreQuick
            selectedStore={formik.values.storeQuick}
            setSelectedStore={(value) =>
              formik.setFieldValue('storeQuick', value)
            }
          />

          <C.Button title="OK" onClick={() => {}} />
          <div className="h-3" />

          <C.BlueLabel title={'步驟2. 選擇檢修的冰櫃、繪圖項目'} />
          <C.Select value="" />
          <C.Select value="" />

          <C.Button title="請求開通" onClick={() => {}} />
        </C.SidePanel>

        <C.ContentPanel>
          <C.BlueLabel title={'步驟3. 查看連線&門市'} />
          <C.BlueLabel className="ml-5" title={'點數示使用情況'} />
          <div className="ml-10 my-3">
            <div>{`開通狀況 : `}</div>
            <div>{`可用點數 : `}</div>
            <div>{`使用時間累計 : `}</div>
          </div>

          <C.BlueLabel className="ml-5" title={'門市資訊'} />
          <div className="ml-10 grid grid-cols-6 gap-5 my-3">
            <div className="text-right">{`門市電話 : `}</div>
            <input className="border border-gray-500" />

            <div className="text-right">{`維修商 : `}</div>
            <div>{`xxxxxxx`}</div>

            <div className="text-right">{`註記 : `}</div>
            <input className="border border-gray-500" />

            <div className="text-right">{`安寶帳號 : `}</div>
            <input className="border border-gray-500" />

            <div className="text-right">{`開幕日 : `}</div>
            <div>{`yyyy/mm/dd : `}</div>

            <div className="text-right">{`終止服務時間 : `}</div>
            <div>{`yyyy/mm/dd : `}</div>

            <div className="text-right">{`統一編號 : `}</div>
            <input className="border border-gray-500" />

            <div className="text-right">{`地址 : `}</div>
            <input className="border border-gray-500 col-span-3" />
          </div>

          <div className="h-10" />

          <C.BlueLabel title={'步驟4. 線上檢修'} />
          <Step4 />
          <div className="h-10" />

          <C.BlueLabel title={'步驟5. 調整參數'} />
          <div className="flex items-center justify-between mb-3">
            <C.BlueLabel className="ml-5" title={'冰櫃參數'} />
            <C.Button title="另存成參數檔" />
          </div>

          <div className="flex flex-col">
            <textarea className="border border-gray-500" rows={10} />
          </div>
          <div className="h-10" />
        </C.ContentPanel>
      </div>
    </div>
  );
};

const Step4 = () => {
  return (
    <>
      <C.BlueLabel className="ml-5" title={'遠端智能錶頭'} />
      <C.BlueLabel className="ml-5" title={'故障與排除建議'} />
      <C.BlueLabel className="ml-5" title={'即時繪圖'} />
    </>
  );
};
