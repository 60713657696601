export const Card1 = ({
  img,
  date,
  desc,
  onDelete,
}: {
  img: string;
  date: string;
  desc: string;
  onDelete: () => void;
}) => (
  <div style={{ minWidth: 128, maxWidth: 128 }}>
    <div className="relative w-32 h-32">
      <img className="w-32 h-32 bg-gray-100" src={img} />

      <div
        className="absolute bottom-0 right-0 w-8 h-8 bg-red-200 cursor-pointer flex items-center justify-center"
        onClick={onDelete}
      >
        <i className="fa fa-close text-red-500" />
      </div>
    </div>

    <div>{date}</div>

    <div>{desc}</div>
  </div>
);
