import React from 'react';
import axios from 'axios';

import * as antd from 'antd';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import i18n from './i18n';

const MsgBox = ({
  api,
  msg,
  onCancel,
}: {
  api: string;
  msg: string;
  onCancel: () => void;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="text-white text-center py-1 bg-error">{`cloud 連線異常`}</div>

      <div className="p-3">
        <div>{`API : ${api}`}</div>
        <div>{`內容 : ${msg}`}</div>

        <div className="flex justify-end">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

interface User {
  name: string;
  home: string;
  menu: { [key: string]: boolean };
}

interface Config {
  url: string;
  port: string;
  color_nav: string;
  color_button: string;
  color_error: string;
  title: string;
  vendor_icon: string;
  vendor_title: string;
  languages: Array<{ title: string; value: string }>;
}

interface AppContextProps {
  appTitle: string;
  account: string;

  initialized: boolean;
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>;

  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;

  token: string | undefined;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;

  config: Config;
  setConfig: React.Dispatch<React.SetStateAction<Config>>;

  setModal: (modal: React.ReactNode, width?: number) => void;
  setModal2: (modal: React.ReactNode, width?: number) => void;
  setModalError: (modal: React.ReactNode, width?: number) => void;

  fetchApi: (method: HttpMethod, url: string, data?: any) => Promise<any>;
  fetchApi2: (method: HttpMethod, url: string, data?: any) => Promise<any>;

  login: (account: string, password: string) => Promise<any>;
  logout: () => Promise<void>;

  languages: string[];

  language: string;
  setLanguage: (value: string) => void;

  getTimezone: string;
  timeFormat: (value: any) => string;
}

export const AppContext = React.createContext<AppContextProps>(undefined!);

interface AppProviderProps {
  debug?: boolean;
  children: React.ReactNode;
}

export const AppProvider = ({ debug = false, children }: AppProviderProps) => {
  const appTitle = '';
  const account = '';

  const [initialized, setInitialized] = React.useState(false);
  const [user, setUser] = React.useState<User | undefined>(undefined);
  const [token, setToken] = React.useState<string | undefined>(undefined);
  const [modalContent, setModalContent] = React.useState<React.ReactNode>(null);
  const [modalWidth, setModalWidth] = React.useState<number>(350);
  const [modalContent2, setModalContent2] =
    React.useState<React.ReactNode>(null);
  const [modalWidth2, setModalWidth2] = React.useState<number>(350);
  const [modalContentError, setModalContentError] =
    React.useState<React.ReactNode>(null);
  const [modalWidthError, setModalWidthError] = React.useState<number>(350);
  const [language, setLanguage] = React.useState<string>('');

  const [config, setConfig] = React.useState<Config>({
    url: '',
    port: '',
    color_nav: '#6890B1',
    color_button: '#67AB9F',
    color_error: '#CF7796',
    title: '',
    vendor_icon: '',
    vendor_title: '',
    languages: [
      { title: 'English', value: 'en' },
      { title: '繁體中文', value: 'zh-TW' },
    ],
  });

  React.useEffect(() => {
    const fetchConfig = async () => {
      const res = await fetch('/config.json');
      const json = await res.json();
      setConfig(json);

      if (!debug) {
        const _token = window.localStorage.getItem('token') || '';
        const _user = window.localStorage.getItem('user') || '';

        if (_token === '' && window.location.hash !== '#/login') {
          window.location.href = '/#/login';
        } else {
          setToken(_token);
          setUser(JSON.parse(_user));
        }
      } else {
        setToken(
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJ1c2VybmFtZSI6ImRldnRAZ21haWwuY29tIiwiZXhwIjoxNjQ2MjA5NDM0LCJlbWFpbCI6ImRldnRAZ21haWwuY29tIiwicGFnZV9hdXRoIjp7IjAtMSI6dHJ1ZSwiMC0yIjp0cnVlLCIxLTEiOnRydWUsIjEtMiI6dHJ1ZSwiMS0zIjp0cnVlLCIxLTQiOnRydWUsIjEtNSI6dHJ1ZSwiMS03Ijp0cnVlLCIxLTgiOnRydWUsIjItMS0xIjp0cnVlLCIyLTEtMiI6dHJ1ZSwiMi0xLTMiOnRydWUsIjItMi0xIjp0cnVlLCIyLTItMiI6dHJ1ZSwiMi0yLTMiOnRydWUsIjItMi00Ijp0cnVlLCIyLTItNSI6dHJ1ZSwiMi0zLTEiOnRydWUsIjItMy0yIjp0cnVlLCIyLTMtMyI6dHJ1ZSwiMi00Ijp0cnVlLCIzLTEiOmZhbHNlLCIzLTIiOmZhbHNlLCIzLTMiOmZhbHNlLCIzLTQiOmZhbHNlLCI0LTEiOnRydWUsIjQtMiI6ZmFsc2UsIjQtMyI6ZmFsc2UsIjQtNCI6ZmFsc2UsIjQtNSI6ZmFsc2UsIjQtNiI6ZmFsc2UsIjUtMSI6ZmFsc2UsIjUtMiI6ZmFsc2V9fQ.CzoPKRZAzmiTNkPXvxcnt4WXt2kVbFZqz9LrBD2ic9c',
        );
      }

      setInitialized(true);
    };

    fetchConfig();
  }, []);

  React.useEffect(() => {
    const loadLanguage = async () => {
      const res = await fetch(`/lang-${language}.json`);
      const json = await res.json();
      i18n.addResourceBundle(
        language.replaceAll('-', ''),
        'translation',
        json,
        true,
        true,
      );

      i18n.changeLanguage(language.replaceAll('-', ''));
    };

    if (language !== '') {
      loadLanguage();
    }
  }, [language]);

  React.useEffect(() => {
    document.documentElement.style.setProperty('--color_nav', config.color_nav);
    document.documentElement.style.setProperty(
      '--color_button',
      config.color_button,
    );
    document.documentElement.style.setProperty(
      '--color_error',
      config.color_error,
    );

    window.document.title = config.title;

    // axios.defaults.baseURL = config.url + ':' + config.port;
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          window.location.href = '/#/login';
        }
        return Promise.reject(error);
      },
    );

    const languages = window.navigator.languages;
    for (const lang of languages) {
      const r = config.languages.map((item) => item.value).includes(lang);
      if (r) {
        setLanguage(lang);
        break;
      }
    }
  }, [config]);

  const setModal = (modal: React.ReactNode, width?: number) => {
    setModalWidth(width ?? 350);
    setModalContent(modal);
  };

  const setModal2 = (modal: React.ReactNode, width?: number) => {
    setModalWidth2(width ?? 350);
    setModalContent2(modal);
  };

  const setModalError = (modal: React.ReactNode, width?: number) => {
    setModalWidthError(width ?? 350);
    setModalContentError(modal);
  };

  const fetchApi = async (method: HttpMethod, url: string, data?: any) => {
    let responseData: any = null;

    try {
      const response = await axios({
        method,
        url,
        data,
        headers: {
          Authorization: `jwt ${token}`,
          language: language,
        },
      });

      responseData = response.data;
    } catch (error: any) {
      if (error.response) {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.response.data.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else if (error.request) {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      }
    }

    return responseData;
  };

  const login = async (account: string, password: string) => {
    let responseData: any = null;

    try {
      const response = await axios({
        method: 'post',
        url: '/api/v2/auth',
        data: { push_token: 'token12345' },
        headers: {
          Authorization: `Basic ` + btoa(`${account}:${password}`),
          language: language,
        },
      });

      setToken(response.data.token);
      setUser(response.data.user);

      window.localStorage.setItem('token', response.data.token);
      window.localStorage.setItem('user', JSON.stringify(response.data.user));

      responseData = response.data;
    } catch (error: any) {
      if (error.response) {
        setModalError(
          <MsgBox
            api="post /api/v2/auth"
            msg={error.response.data.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else if (error.request) {
        setModalError(
          <MsgBox
            api="post /api/v2/auth"
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else {
        setModalError(
          <MsgBox
            api="post /api/v2/auth"
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      }
    }

    return responseData;
  };

  const logout = async () => {};

  const fetchApi2 = async (method: HttpMethod, url: string, data?: any) => {
    let responseData: any = null;

    const token2 =
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2MjI3Nzc5MzIsImV4cCI6MTY1NDMxMzkzNywiYXVkIjoiIiwic3ViIjoiIiwibWVtYmVyIjoiamFuZ29AZ21haWwuY29tIn0.6LaKOHpBPL9x2UrroR6HZ-4ubmvATK-Ib2TIhOCPcno';

    try {
      const response = await axios({
        method,
        url: 'https://amber2.credot.ml' + url,
        data,
        headers: {
          Authorization: `JWT ${token2}`,
          language: language,
        },
      });

      responseData = response.data;
    } catch (error: any) {
      if (error.response) {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.response.data.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else if (error.request) {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      } else {
        setModalError(
          <MsgBox
            api={`${method} ${url}`}
            msg={error.message}
            onCancel={() => setModalError(null)}
          />,
        );
      }
    }

    return responseData;
  };

  return (
    <AppContext.Provider
      value={{
        appTitle,
        account,

        initialized,
        setInitialized,

        user,
        setUser,

        token,
        setToken,

        config,
        setConfig,

        setModal,
        setModal2,
        setModalError,

        fetchApi,
        fetchApi2,

        login,
        logout,

        languages: ['en', 'ja', 'zhHant', 'zhHans'],

        language,
        setLanguage,

        getTimezone: `+0${-new Date().getTimezoneOffset() / 60}:00`,
        timeFormat: (value: any) => moment(value).format('YYYY-MM-DDTHH:mm:ss'),
      }}
    >
      {children}

      <antd.Modal
        visible={modalContent !== null}
        width={modalWidth}
        footer={null}
        closable={false}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
      >
        {modalContent}
      </antd.Modal>

      <antd.Modal
        visible={modalContent2 !== null}
        width={modalWidth2}
        footer={null}
        closable={false}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
      >
        {modalContent2}
      </antd.Modal>

      <antd.Modal
        visible={modalContentError !== null}
        width={modalWidthError}
        footer={null}
        closable={false}
        destroyOnClose
        bodyStyle={{ padding: 0 }}
      >
        {modalContentError}
      </antd.Modal>
    </AppContext.Provider>
  );
};
