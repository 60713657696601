import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface Option {
  title: string;
  value: string;
}

export interface SelectProps {
  className?: string;
  value: string;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  options?: Option[];
}

export const Select = ({
  className,
  value,
  onValueChange,
  placeholder,
  options,
}: SelectProps) => {
  const { t, i18n } = useTranslation();

  return (
    <select
      className={clsx(
        'h-10 border border-gray-500 px-2 text-gray-500',
        className,
      )}
      value={value}
      onChange={(evt) => onValueChange && onValueChange(evt.target.value)}
      placeholder={placeholder}
    >
      <option value="" disabled selected hidden className="opacity-50">
        {t('Please select')}
      </option>
      {options?.map((item, index) => (
        <option key={index.toString()} value={item.value}>
          {item.title}
        </option>
      ))}
    </select>
  );
};
