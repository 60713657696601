import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import clsx from 'clsx';

const ChangePassword = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    initialValues: {
      password: '',
      password2: '',
    },
    onSubmit: async (values) => {
      appCtx
        .fetchApi('patch', '/api/v2/account/passwd', {
          passwd: values.password,
        })
        .then((result) => {
          if (result) {
            onOK();
          }
        });
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{`更換密碼`}</div>

      <div className="p-3 space-y-3">
        <C.TextInput
          className="w-full"
          placeholder={t('Please enter your password')}
          value={formik.values.password}
          onValueChange={(value) => formik.setFieldValue('password', value)}
          type="password"
        />

        <C.TextInput
          className="w-full"
          placeholder={t('Please confirm your password again')}
          value={formik.values.password2}
          onValueChange={(value) => formik.setFieldValue('password2', value)}
          type="password"
        />

        <div className="flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
              (!formik.values.password ||
                !formik.values.password2 ||
                formik.values.password !== formik.values.password2) &&
                'opacity-50',
            )}
            onClick={() => formik.submitForm()}
            disabled={
              !formik.values.password ||
              !formik.values.password2 ||
              formik.values.password !== formik.values.password2
            }
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [departmentMap, setDepartmentMap] = React.useState<string[]>([]);
  const [accountInfo, setAccountInfo] = React.useState<any>(null);
  const [accountProfile, setAccountProfile] = React.useState<any>(null);

  const formik = useFormik({
    initialValues: {
      name: '',
      department: '',
      title: '',
      cellphone: '',
      phone: '',
    },
    onSubmit: async (values) => {
      appCtx
        .fetchApi('patch', '/api/v2/account/profile', {
          name: values.name,
          department_id: values.department,
          title: values.title,
          cellphone: values.cellphone,
          phone: values.phone,
        })
        .then((result) => {
          if (result) {
            alert('儲存成功');
          }
        });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx
        .fetchApi('get', '/api/v2/account/info')
        .then((result) => setAccountInfo(result));

      appCtx.fetchApi('get', '/api/v2/account/profile').then((result) => {
        setAccountProfile(result);

        formik.setFieldValue('name', result.name);
        formik.setFieldValue('department', result.department);
        formik.setFieldValue('title', result.title);
        formik.setFieldValue('cellphone', result.cellphone);
        formik.setFieldValue('phone', result.phone);
      });

      appCtx
        .fetchApi('get', '/api/v2/department-map')
        .then((result) =>
          setDepartmentMap(result.tree.children.map((item: any) => item.name)),
        );
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[]} current={t('Modification')} />

      <div className="mt-5" />

      <div className="flex-1 flex flex-col">
        <C.BlueLabel className="px-5" title={t('Basic information')} />

        <div className="mx-10 my-10 p-5" style={{ backgroundColor: '#EEEEEE' }}>
          <div className="text-base">{`${t('Uniform numbers')} / ${t(
            'Company Name',
          )}：${accountInfo?.company}`}</div>
          <div className="text-base">{`${t('Company representative')}：${
            accountInfo?.company_ceo
          }`}</div>
          <div className="text-base">{`${t('Company address')}：${
            accountInfo?.company_addr
          }`}</div>
          <div className="text-base">{`${t('Service arrival day')}：${
            accountInfo?.service_due_dt
          }`}</div>
        </div>

        <C.BlueLabel className="px-5" title={t('Contact information')} />

        <div
          className="mx-10 my-10 flex space-x-5 p-5"
          style={{ backgroundColor: '#EEEEEE' }}
        >
          <div className="flex-1 flex flex-col space-y-3">
            <div className="flex items-center">
              <div className="text-base w-36">{t('Name')}</div>
              <C.TextInput
                className="flex-1"
                value={formik.values.name}
                onValueChange={(value) => formik.setFieldValue('name', value)}
              />
            </div>

            <div className="flex items-center">
              <div className="text-base w-36">{t('Department')}</div>
              <C.Select
                className="flex-1"
                options={departmentMap.map((item) => ({
                  title: item,
                  value: item,
                }))}
                value={formik.values.department}
                onValueChange={(value) =>
                  formik.setFieldValue('department', value)
                }
              />
            </div>

            <div className="flex items-center">
              <div className="text-base w-36">{t('Job title')}</div>
              <C.TextInput
                className="flex-1"
                value={formik.values.title}
                onValueChange={(value) => formik.setFieldValue('title', value)}
              />
            </div>

            <div className="flex items-center">
              <div className="text-base w-36">
                {t('Contact phone (mobile phone)')}
              </div>
              <C.TextInput
                className="flex-1"
                value={formik.values.cellphone}
                onValueChange={(value) =>
                  formik.setFieldValue('cellphone', value)
                }
              />
            </div>

            <div className="flex items-center">
              <div className="text-base w-36">
                {t('Contact phone (local call)')}
              </div>
              <C.TextInput
                className="flex-1"
                value={formik.values.phone}
                onValueChange={(value) => formik.setFieldValue('phone', value)}
              />
            </div>

            <div className="text-base">{`${t('Registration date')}：${
              accountProfile?.dt_register
            }`}</div>
          </div>

          <div className="flex flex-col w-72 space-y-3">
            <C.Button
              title={t('Update password')}
              onClick={() =>
                appCtx.setModal(
                  <ChangePassword
                    onOK={() => appCtx.setModal(null)}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                )
              }
            />
            <C.Button
              title={t('Save')}
              onClick={() => {
                formik.submitForm();
              }}
            />
          </div>
        </div>

        <div className="mx-5 mb-10">
          {accountProfile?.engineers && accountProfile.engineers.length > 0 && (
            <C.Table2
              label="維修工程師"
              header={{
                value: [
                  t('Name'),
                  t('Mobile'),
                  t('Department'),
                  t('Job title'),
                  t('Account'),
                  t('Password'),
                  'email',
                  '安保到期日',
                ],
              }}
              data={accountProfile.engineers.map((item: any) => ({
                value: [
                  item.name,
                  item.phone,
                  item.department,
                  item.job_title,
                  item.account,
                  item.password,
                  item.email,
                  item.amber_due_dt,
                ],
              }))}
            />
          )}
        </div>
      </div>
    </div>
  );
};
