import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';

import * as CC from './_m_43_';
import * as CD from './_m_delete';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);
  const [maxImages, setMaxImages] = React.useState<number>(0);

  const [selectedCfg, setSelectedCfg] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi2('get', `/api/v2/maintain/cfg?depart-id=${values.department}`)
        .then((result) => {
          if (result) {
            // alert(JSON.stringify(result.rules));

            const rows = result.rules.map((item: any) => ({
              id: item.id,
              value: [
                item.store_id,
                item.store_name,
                item.item,
                item.period + item.period_unit,
                item.dt_start,
                item.next_days,
                () => {
                  return <C.Button title="完成" />;
                },
                item.note,
              ],
            }));

            setData({
              table: {
                lable: '',
                header: {
                  value: [
                    '店號',
                    '店名',
                    '保養品項',
                    '保養週期',
                    '起算日',
                    '下次保養',
                    '完成保養',
                    '備註',
                  ],
                  bg_color: Array(rows.length).fill('#999999'),
                  txt_color: Array(rows.length).fill('#FFFFFF'),
                },
                data: rows,
                page_size: 10,
                filter_index: -1,
              },
            });
          }
        });
    },
  });

  const refresh = () => {
    // appCtx.fetchApi2(
    //   'get',
    //   `/api/v2/store/service?storeid=123456&serviceid=9`,
    // );

    appCtx
      .fetchApi2('get', `/api/v2/maintain/attach/max-images`)
      .then((result) => setMaxImages(result.max_images));
  };

  React.useEffect(() => {
    if (appCtx.token) {
      refresh();
    }
  }, [appCtx.token]);

  React.useEffect(() => {
    if (!selectedCfg) {
      return;
    }

    appCtx
      .fetchApi2('get', `/api/v2/maintain/records?cfg-id=${selectedCfg}`)
      .then((result) => {});
  }, [selectedCfg]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'保養進度回報'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇組織'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />

          <div className="h-3" />

          <C.Button
            title={'搜尋'}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel fixedHeight>
          <ReflexContainer orientation="horizontal">
            <ReflexElement flex={2}>
              <C.BlueLabel title={'保養週期'} />
              {data && (
                <C.Table2WithAction
                  label={data?.table?.title}
                  header={data?.table?.header}
                  data={data?.table?.data}
                  page_size={data?.table?.page_size}
                  filter_index={data?.table?.filter_index}
                  showTitle={false}
                  onNew={() => {
                    appCtx.setModal(
                      <CC.NewT1
                        onOK={() => {
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                  onEdit={(value) => {
                    appCtx.setModal(
                      <CC.EditT1
                        item={value}
                        onOK={() => {
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                  onDelete={(value) => {
                    appCtx.setModal(
                      <CD.DeleteMsgBox
                        onOK={async () => {
                          await appCtx.fetchApi(
                            'delete',
                            `/api/v2/append-poll/poll-mid?mid=${value.mid}`,
                          );
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                  onSelected={(value) => {
                    appCtx
                      .fetchApi2(
                        'get',
                        `/api/v2/maintain/records?cfg-id=${value.id}`,
                      )
                      .then((result) => {
                        if (result) {
                          // alert(JSON.stringify(result));
                          setData2(result.records);
                        }
                      });
                  }}
                />
              )}
            </ReflexElement>

            {/* splitter */}
            <ReflexSplitter
              className="my-5"
              style={{
                backgroundColor: 'rgb(104,144,177)',
                border: 'none',
                height: 1,
              }}
            />

            <ReflexElement flex={1}>
              <C.BlueLabel title={'保養紀錄'} />
              {data2 && (
                <C.ComponentSlider>
                  {Array.from({ length: 10 }).map((_, i) => (
                    <C.Card1
                      img="sss"
                      date="dsads"
                      desc="dfsfsdfsdf"
                      onDelete={() => {}}
                    />
                  ))}

                  <div
                    className="w-20 h-20 bg-button flex justify-center items-center cursor-pointer"
                    style={{ minWidth: 80 }}
                  >
                    <i
                      className="fa fa-plus"
                      style={{ color: 'white', fontSize: 30 }}
                    />
                  </div>

                  <div
                    className="w-20 h-20 bg-button flex justify-center items-center cursor-pointer"
                    style={{ minWidth: 80 }}
                  >
                    <div className="text-white text-lg">儲存</div>
                  </div>
                </C.ComponentSlider>
              )}
            </ReflexElement>
          </ReflexContainer>
        </C.ContentPanel>
      </div>
    </div>
  );
};
