import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

import * as CC from './_m_appendPolling';
import * as CD from './_m_delete';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [projects, setProjects] = React.useState<any[]>([]);
  const [scripts, setScripts] = React.useState<any[]>([]);

  const [selectedProject, setSelectedProject] = React.useState<string>('');
  const [selectedScript, setSelectedcript] = React.useState<string>('');

  const [data1, setData1] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);

  const refresh = () => {
    appCtx.fetchApi('get', '/api/v2/append-poll-mgr/project').then((result) => {
      if (result) {
        setProjects(
          result.projects.map((item: any) => ({
            title: item.name,
            value: item.id,
          })),
        );
      }
    });

    appCtx
      .fetchApi('get', '/api/v2/append-poll-mgr/script-file?project=324')
      .then((result) => {
        if (result) {
          setScripts(
            result.scripts.map((item: any) => ({
              title: item.name + ' 週期:' + item.duration,
              value: item.id,
            })),
          );
        }
      });

    appCtx
      .fetchApi(
        'get',
        '/api/v2/append-poll-mgr/cmd-collection?script-file=24951&cmd-alias=read-all-func',
      )
      .then((result) => {
        if (result) {
          setData1(result);
        }
      });

    appCtx
      .fetchApi(
        'get',
        '/api/v2/append-poll-mgr/alarm-rule?project=123&script-file=456&cmd-alias=read-all-func',
      )
      .then((result) => {
        if (result) {
          setData2(result);
        }
      });

    appCtx
      .fetchApi('get', '/api/v2/append-poll-mgr/patch-to-store/status')
      .then((result) => {
        if (result) {
          alert(JSON.stringify(result));
        }
      });
  };

  React.useEffect(() => {
    if (appCtx.token) {
      refresh();
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('ApendPoll Management')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          <div className="flex flex-col space-x-3 mb-3">
            <C.BlueLabel
              className="mb-3"
              title={`1.${t('Manage Script')}`}
              noDash
            />

            <div className="flex space-x-5">
              <div className="flex-1">
                <div className="flex justify-between mb-3">
                  <div>{t('Folder')}</div>
                  <C.Button
                    title="+"
                    onClick={() => {
                      appCtx.setModal(
                        <CC.New1
                          onOK={() => {
                            refresh();
                            appCtx.setModal(null);
                          }}
                          onCancel={() => appCtx.setModal(null)}
                        />,
                      );
                    }}
                  />
                </div>

                <C.SelectableListWithAction
                  data={projects}
                  value={selectedProject}
                  onValueChange={setSelectedProject}
                  onEdit={(value) => {
                    appCtx.setModal(
                      <CC.Edit1
                        item={value}
                        onOK={() => {
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                  onDelete={(value) => {
                    appCtx.setModal(
                      <CD.DeleteMsgBox
                        onOK={async () => {
                          await appCtx.fetchApi(
                            'delete',
                            `/api/v2/append-poll-mgr/project?id=${value.value}`,
                          );
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                />
              </div>

              <div className="flex-1">
                <div className="flex justify-between mb-3">
                  <div>{t('Script file')}</div>

                  <div className="flex space-x-3">
                    <C.Button
                      title="+"
                      onClick={() => {
                        appCtx.setModal(
                          <CC.New2
                            onOK={() => {
                              refresh();
                              appCtx.setModal(null);
                            }}
                            onCancel={() => appCtx.setModal(null)}
                          />,
                        );
                      }}
                    />
                    <C.Button
                      title={t('Save As')}
                      onClick={async () => {
                        await appCtx.fetchApi(
                          'post',
                          '/api/v2/append-poll-mgr/script-files/save-as',
                          // values,
                        );
                      }}
                    />
                  </div>
                </div>

                <C.SelectableListWithAction
                  data={scripts}
                  value={selectedScript}
                  onValueChange={setSelectedcript}
                  onEdit={(value) => {
                    appCtx.setModal(
                      <CC.Edit2
                        item={value}
                        onOK={() => {
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                  onDelete={(value) => {
                    appCtx.setModal(
                      <CD.DeleteMsgBox
                        onOK={async () => {
                          await appCtx.fetchApi(
                            'delete',
                            `/api/v2/append-poll-mgr/script-file?id=${value.value}`,
                          );
                          refresh();
                          appCtx.setModal(null);
                        }}
                        onCancel={() => appCtx.setModal(null)}
                      />,
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {data1 && (
            <C.Table2WithAction
              label={data1?.table?.title}
              header={data1?.table?.header}
              data={data1?.table?.data}
              page_size={data1?.table?.page_size}
              filter_index={data1?.table?.filter_index}
              showTitle={false}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT1
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                    scriptFile={selectedScript}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT1
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/append-poll-mgr/cmd-collection?id=${value.uid}`,
                        value,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
            />
          )}

          <div className="h-5" />
          <div className="h-5" />

          <div className="flex flex-col space-x-3 mb-3">
            <C.BlueLabel
              className="mb-3"
              title={`2.${t('Report Setting')}`}
              noDash
            />
            {data2 && (
              <C.Table2WithAction
                label={data2?.table?.title}
                header={data2?.table?.header}
                data={data2?.table?.data}
                page_size={data2?.table?.page_size}
                filter_index={data2?.table?.filter_index}
                showTitle={false}
                onNew={() => {
                  appCtx.setModal(
                    <CC.NewT2
                      onOK={() => {
                        refresh();
                        appCtx.setModal(null);
                      }}
                      onCancel={() => appCtx.setModal(null)}
                      scriptFile={selectedScript}
                      project={selectedProject}
                    />,
                  );
                }}
                onEdit={(value) => {
                  appCtx.setModal(
                    <CC.EditT2
                      item={value}
                      onOK={() => {
                        refresh();
                        appCtx.setModal(null);
                      }}
                      onCancel={() => appCtx.setModal(null)}
                      scriptFile={selectedScript}
                      project={selectedProject}
                    />,
                  );
                }}
                onDelete={(value) => {
                  appCtx.setModal(
                    <CD.DeleteMsgBox
                      onOK={async () => {
                        await appCtx.fetchApi(
                          'delete',
                          `/api/v2/append-poll-mgr/alarm-rule?id=${value.uid}`,
                          value,
                        );
                        refresh();
                        appCtx.setModal(null);
                      }}
                      onCancel={() => appCtx.setModal(null)}
                    />,
                  );
                }}
              />
            )}
          </div>

          <div className="h-5" />
          <div className="h-5" />

          <div className="flex flex-col space-x-3 mb-3">
            <C.BlueLabel className="mb-3" title={`3.${t('Download')}`} noDash />
          </div>

          <div className="flex space-x-5">
            <div className="flex-1">
              <C.SelectableList value="" />
            </div>
            <div className="flex-1">
              <C.SelectableList value="" />
            </div>
            <div className="flex-1">
              <C.SelectableList value="" />
            </div>

            <div className="flex-1 space-y-5 flex flex-col">
              <C.Button title={t('Clear')} />
              <C.Button title={t('Start download')} />
            </div>
          </div>

          <div>{t('Result')}</div>
          <C.SelectableList value="" />
        </C.ContentPanel>
      </div>
    </div>
  );
};
