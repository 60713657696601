import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

import * as CC from './_m_instra';
import * as CD from './_m_delete';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data1, setData1] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);
  const [data3, setData3] = React.useState<any>(null);

  const refresh = () => {
    appCtx
      .fetchApi('get', `api/v2/append-poll/poll-mid?brand=chisu&model=TC6000`)
      .then((result) => {
        if (result) {
          setData1(result);
        }
      });

    appCtx
      .fetchApi('get', `api/v2/append-poll/poll-uid?mid=p12345`)
      .then((result) => {
        if (result) {
          setData2(result);
        }
      });

    appCtx.fetchApi('get', `api/v2/append-poll/write-mid`).then((result) => {
      if (result) {
        setData3(result);
      }
    });
  };

  React.useEffect(() => {
    if (appCtx.token) {
      refresh();
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Instruction table')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          {data1 && (
            <C.Table2WithAction
              label={`1. ${t('Poll Directive Table (MID)')}`}
              header={data1?.table?.header}
              data={data1?.table?.data}
              page_size={data1?.table?.page_size}
              filter_index={data1?.table?.filter_index}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT1
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT1
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/append-poll/poll-mid?mid=${value.mid}`,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
            />
          )}

          <div className="h-3" />
          <div className="h-3" />

          {data2 && (
            <C.Table2WithAction
              label={`2. ${t('Node table (UID)')}`}
              header={data2?.table?.header}
              data={data2?.table?.data}
              page_size={data2?.table?.page_size}
              filter_index={data2?.table?.filter_index}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT2
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT2
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/append-poll/poll-uid?uid=${value.uid}`,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
            />
          )}

          <div className="h-3" />
          <div className="h-3" />

          {data3 && (
            <C.Table2WithAction
              label={`3. ${t('Write command table (MID)')}`}
              header={data3?.table?.header}
              data={data3?.table?.data}
              page_size={data3?.table?.page_size}
              filter_index={data3?.table?.filter_index}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT3
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT3
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/append-poll/write-mid?mid=${value.mid}`,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
            />
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
