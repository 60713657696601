import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';

import clsx from 'clsx';

export const New1 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll-mgr/project', {
        projects: [{ name: values.v1 }],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Input
          label="資料夾名稱"
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const New2 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [projects, setProjects] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/append-poll-mgr/project').then((result) => {
      if (result) {
        setProjects(
          result.projects.map((item: any) => ({
            title: item.name,
            value: item.id,
          })),
        );
      }
    });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll-mgr/script-file', {
        projects: [
          {
            name: values.v2,
            project_id: values.v1,
            duration: Number(values.v3),
          },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Select
          label="資料夾名稱"
          options={projects}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="檔案名稱"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="同步週期"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const Edit1 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.title,
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll-mgr/project', {
        projects: [{ id: item.value, name: values.v1 }],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Input
          label="資料夾名稱"
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const Edit2 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [projects, setProjects] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/append-poll-mgr/project').then((result) => {
      if (result) {
        setProjects(
          result.projects.map((item: any) => ({
            title: item.name,
            value: item.id,
          })),
        );
      }
    });
  }, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: item.title.split(' 週期')[0],
      v3: item.title.split(':')[1],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll-mgr/script-file', {
        projects: [
          {
            id: item.value,
            project_id: values.v1,
            name: values.v2,
            duration: Number(values.v3),
          },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Select
          label="資料夾名稱"
          options={projects}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="檔案名稱"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="同步週期"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewT1 = ({
  onOK,
  onCancel,
  scriptFile,
}: {
  onOK: () => void;
  onCancel: () => void;
  scriptFile: string;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);
  const [option3, setOption3] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      v5: '',
      v6: '',
      v7: '',
      v8: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
      v8: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll-mgr/cmd-collection', {
        'script-file': scriptFile,
        rows: [
          [
            values.v1,
            values.v2,
            values.v3,
            values.v4,
            values.v5,
            Number(values.v6),
            Number(values.v7),
            Number(values.v8),
          ],
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      // formik.setFieldValue('v2', '');
      // formik.setFieldValue('v3', '');
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  React.useEffect(() => {
    if (r && formik.values.v1 && formik.values.v2) {
      appCtx
        .fetchApi(
          'get',
          `api/v2/append-poll/poll-mid?brand=${formik.values.v1}&model=${formik.values.v2}`,
        )
        .then((result) => {
          if (result) {
            setOption3(
              result.table.data.map((item: any) => ({
                title: item.value[2],
                value: item.value[2],
              })),
            );
          }
        });
    }
  }, [r, formik.values.v1, formik.values.v2]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Select
          label="指令代號"
          options={option3}
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="使用ID"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="設備名稱"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="重試次數"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="指令間隔時間ms"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Input
          label="等待逾時ms"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewT2 = ({
  onOK,
  onCancel,
  scriptFile,
  project,
}: {
  onOK: () => void;
  onCancel: () => void;
  scriptFile: string;
  project: string;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      v5: '',
      v6: '',
      v7: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll-mgr/alarm-rule', {
        script_file: scriptFile,
        project: project,
        script_cmd: '154',
        rows: [
          [
            values.v1,
            values.v2,
            Number(values.v3),
            Number(values.v4),
            Number(values.v5),
            Number(values.v6),
            Number(values.v7),
          ],
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Select
          label="節點名稱"
          options={[{ title: '此處規格書未定義', value: '此處規格書未定義' }]}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="單位"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="過低門檻"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="過低發報時間"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="過高門檻"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="過高發報時間"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="失聯發報時間"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT1 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);
  const [option3, setOption3] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
      v5: item.value[4],
      v6: item.value[5].toString(),
      v7: item.value[6].toString(),
      v8: item.value[7].toString(),
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
      v8: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll-mgr/cmd-collection', {
        collections: [
          {
            id: item.uid,
            row: [
              values.v1,
              values.v2,
              values.v3,
              values.v4,
              values.v5,
              Number(values.v6),
              Number(values.v7),
              Number(values.v8),
            ],
          },
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      // formik.setFieldValue('v2', '');
      // formik.setFieldValue('v3', '');
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  React.useEffect(() => {
    if (r && formik.values.v1 && formik.values.v2) {
      appCtx
        .fetchApi(
          'get',
          `api/v2/append-poll/poll-mid?brand=${formik.values.v1}&model=${formik.values.v2}`,
        )
        .then((result) => {
          if (result) {
            setOption3(
              result.table.data.map((item: any) => ({
                title: item.value[2],
                value: item.value[2],
              })),
            );
          }
        });
    }
  }, [r, formik.values.v1, formik.values.v2]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Select
          label="指令代號"
          options={option3}
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="使用ID"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="設備名稱"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="重試次數"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="指令間隔時間ms"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Input
          label="等待逾時ms"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT2 = ({
  item,
  onOK,
  onCancel,
  scriptFile,
  project,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
  scriptFile: string;
  project: string;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2].toString(),
      v4: item.value[3].toString(),
      v5: item.value[4].toString(),
      v6: item.value[5].toString(),
      v7: item.value[6].toString(),
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll-mgr/alarm-rule', {
        script_file: scriptFile,
        project: project,
        script_cmd: '154',
        rows: [
          {
            uid: item.uid,
            row: [
              values.v1,
              values.v2,
              Number(values.v3),
              Number(values.v4),
              Number(values.v5),
              Number(values.v6),
              Number(values.v7),
            ],
          },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Select
          label="節點名稱"
          options={[{ title: '此處規格書未定義', value: '此處規格書未定義' }]}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="單位"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="過低門檻"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="過低發報時間"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="過高門檻"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="過高發報時間"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="失聯發報時間"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
