// done
// TODO : file chooser

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeQuick, setStoreQuick] = React.useState<string>('');
  const [data, setData] = React.useState<any>(null);
  const [files, setFiles] = React.useState<FileList | null>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
    },
    validationSchema: Yup.object().shape({
      // department: Yup.string().required(t('Required')),
      // store: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      let apiUrl = '';
      if (values.store) {
        apiUrl = `/api/v2/util/pre-alarm/cfg?storeid=${values.store}`;
      } else {
        apiUrl = `/api/v2/util/pre-alarm/cfg?depart=${values.department}`;
      }

      appCtx.fetchApi('get', apiUrl).then((result) => {
        if (result) {
          setData(result);
        }
      });
    },
  });

  const downloadCsv = (csv: string) => {
    const encodedUri =
      'data:text/csv;charset=utf8,' + encodeURIComponent('\uFEFF' + csv);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Warning')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <SelectStoreQuick
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          <C.Button
            title={t('Read')}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel>
          {data && (
            <>
              <div className="flex mb-3">
                <div className="flex-1 flex flex-col space-y-3">
                  <C.BlueLabel className="mb-3" title={t('Set instructions')} />

                  <div className="text-base ml-5">
                    1.{' '}
                    {t(
                      'Threshold Release: The score is lower than x points, continuous Y minutes',
                    )}
                  </div>
                  <div className="text-base ml-5">
                    2.{' '}
                    {t(
                      'Falling report: fraction decreased x points, exceeding (intensity setting)',
                    )}
                  </div>
                  <div className="text-base ml-5">
                    3.{' '}
                    {t(
                      'Oscillation Report: The score is greater than x points, exceeding (intensity setting)',
                    )}
                  </div>

                  <div className="h-5" />
                </div>

                <div className="flex flex-col space-y-3" style={{ width: 150 }}>
                  <C.Button
                    title={t('Export')}
                    onClick={() => {
                      appCtx
                        .fetchApi(
                          'get',
                          `/api/v2/util/pre-alarm/cfg/csv?depart=${formik.values.department}&store=${formik.values.store}`,
                        )
                        .then((result) => {
                          if (result) {
                            downloadCsv(result.csv);
                          }
                        });
                    }}
                  />

                  <div className="h-5"></div>

                  <input
                    type="file"
                    onChange={(evt) => setFiles(evt.target.files)}
                  />
                  <C.Button
                    title={t('Import')}
                    onClick={() => {
                      if (files) {
                        var reader = new FileReader();
                        reader.readAsText(files[0], 'UTF-8');
                        reader.onload = function (evt) {
                          appCtx
                            .fetchApi(
                              'post',
                              `/api/v2/util/pre-alarm/cfg/csv`,
                              {
                                data: JSON.parse(evt.target?.result as string),
                              },
                            )
                            .then((result) => {
                              if (result) {
                                alert('匯入成功');
                              }
                            });
                        };
                        reader.onerror = function (evt) {
                          alert('error reading file');
                        };
                      }
                    }}
                  />
                </div>
              </div>

              <C.Table2
                label={'參數清單'}
                header={data?.table?.header}
                data={data?.table?.data}
                page_size={data?.table?.page_size}
                filter_index={data?.table?.filter_index}
              />
            </>
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
