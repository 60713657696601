// done

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeQuick, setStoreQuick] = React.useState<string>('');
  const [pageInfo, setPageInfo] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      storeQuick: '',
      durationDate: '',
    },
    validationSchema: Yup.object().shape({
      storeQuick: Yup.string().required(t('Required')),
      durationDate: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      // const start = moment(values.durationDate).startOf('day');
      // const end = moment(values.durationDate).add(7, 'days').endOf('day');
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi('get', `/api/v2/qa-temp/dl/pageinfo`).then((result) => {
        if (result) {
          setPageInfo(result);
          formik.setFieldValue('durationDate', result.default_date);
        }
      });
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Insured')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title="1. 店舖快選" noDash />
          <SelectStoreQuick
            selectedStore={formik.values.storeQuick}
            setSelectedStore={(value) =>
              formik.setFieldValue('storeQuick', value)
            }
          />

          <C.BlueLabel title={`2. ${pageInfo?.duration_title}`} noDash />
          <C.DatePicker
            value={formik.values.durationDate}
            onValueChange={(value) =>
              formik.setFieldValue('durationDate', value)
            }
          />
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex">
            <C.Button
              title={t('Download')}
              onClick={() => {
                appCtx
                  .fetchApi('post', `/api/v2/qa-temp/dl`, {
                    store_id: formik.values.storeQuick,
                    start: moment(formik.values.durationDate).startOf('day'),
                    end: moment(formik.values.durationDate)
                      .add(7, 'days')
                      .endOf('day'),
                  })
                  .then((result) => {
                    if (result) {
                      window.open(result.url);
                    }
                  });
              }}
              disabled={!formik.isValid}
            />
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
