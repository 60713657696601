// done
// TODO: /api/v2/ver-update/status 404

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [taskToken, setTaskToken] = React.useState<string>('');
  const [timerInterval, setTimerInterval] = React.useState<number>(0);

  const [devType, setDevType] = React.useState<any[]>([]);
  const [historyFiles, setHistoryFiles] = React.useState<any[]>([]);

  const [selectedDevType, setSelectedDevType] = React.useState<string>('');
  const [selectedHistoryFiles, setSelectedHistoryFiles] =
    React.useState<string>('');

  const [selectedGroup, setSelectedGroup] = React.useState<number>(0);

  React.useEffect(() => {
    let timerId: any;

    if (timerInterval > 0) {
      timerId = setInterval(() => {
        appCtx
          .fetchApi('get', `/api/v2/ver-update/status?token=${taskToken}`)
          .then((result) => {
            if (result) {
              // setData(result);
            }
          });
      }, timerInterval * 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timerInterval, taskToken]);

  React.useEffect(() => {
    // appCtx.fetchApi('get', `/api/v2/ver-update/status?token=xxx`).then((result) => {
    //   if (result) {
    //     // setData(result);
    //   }
    // });

    if (appCtx.token) {
      appCtx.fetchApi('get', `/api/v2/ver-update/dev-type`).then((result) => {
        if (result) {
          setDevType(
            result.device_type.map((item: any) => ({
              title: item.title,
              value: item.id,
            })),
          );
        }
      });

      appCtx
        .fetchApi('get', `/api/v2/ver-update/history-files?type=xxx`)
        .then((result) => {
          if (result) {
            setHistoryFiles(
              result.device_type.map((item: any) => ({
                title: item,
                value: item,
              })),
            );
          }
        });
    }
  }, [appCtx.token]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',

      vendor: '',

      durationDate: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
      vendor: Yup.string().required(t('Required')),
      durationDate: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {},
  });

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Update job')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          <C.BlueLabel
            className="mb-3"
            title={`1.${t('Select update file')}`}
            noDash
          />

          <div className="flex items-center space-x-3 mb-3">
            <div className="flex-1 flex flex-col space-y-3">
              <C.Select
                options={devType}
                value={selectedDevType}
                onValueChange={setSelectedDevType}
              />
              <C.Select
                options={historyFiles}
                value={selectedHistoryFiles}
                onValueChange={setSelectedHistoryFiles}
              />
            </div>

            <div className="flex-1" />
            <div className="flex-1" />
          </div>

          <C.FilePicker
            title={t('Upload')}
            onClick={() => {
              const f = new FormData();
              f.append('dev-type', 'xxx');
              f.append('file', 'xxx.zip');

              appCtx
                .fetchApi('post', `/api/v2/ver-update/upload`, f)
                .then((result) => {
                  if (result) {
                    // setData(result);
                  }
                });
            }}
          />
          <div className="text-button">{t('Upload success')}</div>

          <div className="h-3" />
          <div className="h-3" />

          <C.BlueLabel
            className="mb-3"
            title={`2.${t('Designated shop')}`}
            noDash
          />

          <div className="flex flex-col space-y-3 mb-3">
            <div className="flex-1 flex flex-col space-y-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  className="mr-3"
                  checked={selectedGroup === 0}
                  onChange={(evt) => setSelectedGroup(0)}
                />
                <div className="text-base">{t('Coursicle')}</div>
              </div>

              <SelectPanel
                selectedDepartment={formik.values.department}
                setSelectedDepartment={(value) =>
                  formik.setFieldValue('department', value)
                }
                selectedStore={formik.values.store}
                setSelectedStore={(value) =>
                  formik.setFieldValue('store', value)
                }
                useVendor={true}
                selectedVendor={formik.values.vendor}
                setSelectedVendor={(value) =>
                  formik.setFieldValue('vendor', value)
                }
              />
            </div>

            <div className="flex-1 flex flex-col space-y-3">
              <div className="flex items-center">
                <input
                  type="radio"
                  className="mr-3"
                  checked={selectedGroup === 1}
                  onChange={(evt) => setSelectedGroup(1)}
                />
                <div className="text-base">{t('Specified list')}</div>
              </div>

              <C.SelectableList data={[]} value="" />
            </div>
          </div>

          <div className="h-3" />
          <div className="h-3" />

          <C.BlueLabel className="mb-3" title={`3.${t('Refresh')}`} noDash />

          <C.Button
            title={t('Read')}
            onClick={() => {
              appCtx
                .fetchApi('post', `/api/v2/ver-update/start`)
                .then((result) => {
                  if (result) {
                    setTaskToken(result.task_token);
                    setTimerInterval(result.refresh);
                  }
                });
            }}
          />

          <div className="flex flex-col space-y-3 mb-3">
            <div className="flex-1 flex items-center space-x-3">
              <div className="flex flex-col">
                <div className="text-base opacity-50">{`${t(
                  'Current progress',
                )}：30%`}</div>
                <div className="text-base opacity-50">{`${t(
                  'Successful store',
                )}：20`}</div>
                <div className="text-base opacity-50">{`${t(
                  'Failure store',
                )}：10`}</div>
                <div className="text-base opacity-50">{`${t(
                  'Received store',
                )}：30`}</div>
                <div className="text-base opacity-50">{`${t(
                  'Total shop',
                )}：100`}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-3 mb-3">
            <C.SelectableList data={[]} value="" />

            <C.SelectableList data={[]} value="" />
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
