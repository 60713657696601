import React from 'react';
import * as antd from 'antd';
import { useTranslation } from 'react-i18next';

import { Button } from '../inputs/Button';

export interface ContentPanelProps {
  children?: React.ReactNode;
  fixedHeight?: boolean;
}

export const ContentPanel = ({
  children,
  fixedHeight = false,
}: ContentPanelProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`flex-1 p-5 ${fixedHeight ? 'content-panel' : ''}`}
      style={{ backgroundColor: '#EEEEEE' }}
    >
      {children}
    </div>
  );
};
