import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../AppContext';
import * as C from '../components';

export const SelectVendor = ({
  depart,
  selectedVendor,
  setSelectedVendor,
}: {
  depart: string;
  selectedVendor: string;
  setSelectedVendor: (value: string) => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [vendor, setVendor] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (appCtx.token && depart !== '') {
      appCtx
        .fetchApi('get', `/api/v2/vendors?depart=${depart}&type=all`)
        .then((result) => {
          if (result) {
            setVendor(
              result.data.map((item: any) => ({
                title: item.name,
                value: item.id,
              })),
            );
          }
        });
    }
  }, [appCtx.token, depart]);

  return (
    <C.Select
      options={vendor}
      value={selectedVendor}
      onValueChange={setSelectedVendor}
    />
  );
};
