// done

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeQuick, setStoreQuick] = React.useState<string>('');

  const [alarmPeriod, setAlarmPeriod] = React.useState<number>(0);
  const [alarmTh, setAlarmTh] = React.useState<number>(0);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
    },
    validationSchema: Yup.object().shape({
      // department: Yup.string().required(t('Required')),
      // store: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      let apiUrl = '';
      if (values.store) {
        apiUrl = `/api/v2/util/lost-alarm/cfg?store=${values.store}`;
      } else {
        apiUrl = `/api/v2/util/lost-alarm/cfg?depart=${values.department}`;
      }

      appCtx.fetchApi('get', apiUrl).then((result) => {
        if (result) {
          setAlarmPeriod(result.alarm_period);
          setAlarmTh(result.alarm_th);
        }
      });
    },
  });

  const downloadCsv = (csv: string) => {
    const encodedUri =
      'data:text/csv;charset=utf8,' + encodeURIComponent('\uFEFF' + csv);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Set the threshold')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={t('Select')} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <C.BlueLabel title={t('店舖快選')} />
          <SelectStoreQuick
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          <C.Button
            title={t('Read')}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex-1 flex flex-col space-y-3">
            <C.BlueLabel title={`*${t('Host lost issues')}`} noDash />

            <div className="flex items-center">
              <div className="text-base opacity-50">持續失聯超過</div>
              <C.TextInput
                className="w-16 mx-1"
                value={`${alarmPeriod}`}
                onValueChange={(value) => setAlarmPeriod(Number(value))}
              />
              <div className="text-base opacity-50">分鐘，發報。</div>
            </div>

            <div className="flex items-center">
              <div className="text-base opacity-50">失聯未解除定期每</div>
              <C.TextInput
                className="w-16 mx-1"
                value={`${alarmTh}`}
                onValueChange={(value) => setAlarmTh(Number(value))}
              />
              <div className="text-base opacity-50">分鐘再提醒。</div>
            </div>

            <div className="flex flex-col space-y-3" style={{ width: 150 }}>
              <C.Button
                title={t('Save')}
                onClick={() => {
                  appCtx
                    .fetchApi('post', `/api/v2/util/lost-alarm/cfg`, {
                      department_id:
                        formik.values.department === ''
                          ? null
                          : formik.values.department,
                      store_id:
                        formik.values.store === '' ? null : formik.values.store,
                      alarm_th: alarmTh,
                      alarm_period: alarmPeriod,
                    })
                    .then((result) => {
                      if (result) {
                        alert('設定成功');
                      }
                    });
                }}
              />
              <C.Button
                title={t('Export')}
                onClick={() => {
                  appCtx
                    .fetchApi(
                      'get',
                      `/api/v2/util/lost-alarm/csv?depart=${formik.values.department}&store=${formik.values.store}`,
                    )
                    .then((result) => {
                      if (result) {
                        downloadCsv(result.csv);
                      }
                    });
                }}
              />
            </div>
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
