import React from 'react';
import * as antd from 'antd';
import { useTranslation } from 'react-i18next';

export interface TableProps {
  columns: Array<{ title: string; dataIndex: string; render?: any }>;
  data?: Array<any>;
  onClick?: (item: any) => void;
}

export const Table = ({ columns, data = [], onClick }: TableProps) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <antd.Table
        className="hidden md:block"
        columns={columns.map((item) => ({
          key: item.dataIndex,
          title: item.title,
          dataIndex: item.dataIndex,
          render: item.render,
        }))}
        dataSource={data}
        bordered
        rowClassName={() => 'bg-white'}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onClick && onClick(record);
            },
          };
        }}
      />

      <div className="w-full overflow-scroll block md:hidden">
        <div style={{ width: 2000 }}>
          <antd.Table
            columns={columns.map((item) => ({
              key: item.dataIndex,
              title: item.title,
              dataIndex: item.dataIndex,
              render: item.render,
            }))}
            dataSource={data}
            bordered
            rowClassName={() => 'bg-white'}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  onClick && onClick(record);
                },
              };
            }}
          />
        </div>
      </div>
    </>
  );
};
