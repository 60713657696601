// done
// TODO : 2-3-2-15

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [projects, setProjects] = React.useState<any[]>([]);
  const [scripts, setScripts] = React.useState<any[]>([]);
  const [storeQuicks, setStoreQuicks] = React.useState<any[]>([]);

  const [selectedProject, setSelectedProject] = React.useState<string>('');
  const [selectedScript, setSelectedScript] = React.useState<string>('');
  const [selectedStoreQuick, setSelectStoreQuick] = React.useState<string>('');

  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    if (!selectedProject) {
      return;
    }

    appCtx
      .fetchApi(
        'get',
        `/api/v2/append-poll-mgr/script-file?project=${selectedProject}`,
      )
      .then((result) => {
        if (result) {
          setScripts(result.scripts);
        }
      });
  }, [selectedProject]);

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx
        .fetchApi('get', `/api/v2/append-poll-mgr/project`)
        .then((result) => {
          if (result) {
            setProjects(result.projects);
          }
        });

      appCtx
        .fetchApi('get', `/api/v2/append-poll-mgr/patch-to-store/status`)
        .then((result) => {
          if (result) {
            setData(result);
          }
        });

      appCtx
        .fetchApi('get', `/api/v2/store-quick?key=${'測試1'}`)
        .then((result) => {
          if (result) {
            setStoreQuicks(result.stores);
          }
        });
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Single execution')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          <div className="flex items-center space-x-3">
            <div className="flex-1 flex flex-col space-y-3">
              <C.BlueLabel title="步驟1:選擇資料夾" noDash />
              <C.SelectableList
                data={projects.map((item) => ({
                  title: `${item.id} ${item.name}`,
                  value: item.id,
                }))}
                value={selectedProject}
                onValueChange={(value) => setSelectedProject(value)}
              />
            </div>

            <div className="flex-1 flex flex-col space-y-3">
              <C.BlueLabel title="步驟2:選擇Script檔" noDash />
              <C.SelectableList
                data={scripts.map((item) => ({
                  title: `${item.id} ${item.name}`,
                  value: item.id,
                }))}
                value={selectedScript}
                onValueChange={(value) => setSelectedScript(value)}
              />
            </div>

            {/* <div className="flex-1 flex flex-col items-center space-y-3">
              <div className="text-base">下載</div>
            </div> */}

            <div className="flex-1 flex flex-col space-y-3">
              <C.BlueLabel title="步驟3:選擇門市店號" noDash />
              <C.SelectableList
                data={storeQuicks.map((item) => ({
                  title: `${item[0]} ${item[1]}`,
                  value: item[0],
                }))}
                value={selectedStoreQuick}
                onValueChange={(value) => setSelectStoreQuick(value)}
              />
            </div>

            <div className="flex flex-col space-y-3" style={{ width: 150 }}>
              <C.Button
                title={t('Clear')}
                onClick={() => {
                  setSelectedProject('');
                  setSelectedScript('');
                  setSelectStoreQuick('');
                }}
              />
              <C.Button
                title={t('Start download')}
                onClick={() => {
                  appCtx
                    .fetchApi(
                      'post',
                      `/api/v2/append-poll-mgr/patch-to-store`,
                      {
                        mode: 'once',
                        script_file: selectedScript,
                        store_ids: [selectedStoreQuick],
                        note: '',
                      },
                    )
                    .then((result) => {
                      if (result) {
                        // setStoreQuick(result.stores[0]);
                      }
                    });
                }}
              />
            </div>
          </div>

          <div className="h-3" />
          <div className="h-3" />

          <div className="flex space-x-2 mb-3">
            <div className="flex-1" />
            <C.Button title={t('Export')} />
            <C.Button title={t('Previous page')} />
            <C.Button title="1" />
            <C.Button title="2" />
            <C.Button title="3" />
            <C.Button title={t('Next page')} />
          </div>

          <C.Table
            columns={[
              { title: '門市', dataIndex: '' },
              { title: 'MID', dataIndex: '' },
              { title: 'Modbus ID', dataIndex: '' },
              { title: 'value', dataIndex: '' },
              { title: '封包', dataIndex: '' },
            ]}
          />
        </C.ContentPanel>
      </div>
    </div>
  );
};
