// TODO: /api/v2/util/auth/devlist 404

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

import * as CC from './_m_userRole';
import * as CC2 from './_m_userRole2';
import * as CD from './_m_delete';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data1, setData1] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);

  const refresh = () => {
    appCtx.fetchApi('get', `/api/v2/util/auth/webpage`).then((result) => {
      if (result) {
        setData1(result);
      }
    });

    appCtx.fetchApi('get', `/api/v2/util/auth/device`).then((result) => {
      if (result) {
        setData2(result);
      }
    });
  };

  React.useEffect(() => {
    if (appCtx.token) {
      refresh();
    }
  }, [appCtx.token]);

  const Ar1 = () => {
    return (
      <div className="flex space-x-2 mb-3 items-center">
        <div className="flex-1" />
        <C.Button
          title="讀取目前設定"
          onClick={() => {
            appCtx
              .fetchApi('get', `/api/v2/util/auth/webpage`)
              .then((result) => {
                if (result) {
                  alert(JSON.stringify(result));
                }
              });
          }}
        />
        <C.Button
          title={`頁面權限類別`}
          onClick={() => {
            appCtx.setModal(<CC2.Type2 />);
          }}
        />
        <C.Button title="洗檔" />
        <a
          onClick={() => {
            appCtx
              .fetchApi('get', `/api/v2/util/auth/page-cfg-eg`)
              .then((result) => {
                if (result) {
                  alert(JSON.stringify(result));
                }
              });
          }}
        >
          {t('Example')}
        </a>
      </div>
    );
  };

  const Ar2 = () => {
    return (
      <div className="flex space-x-2 mb-3 items-center">
        <div className="flex-1" />
        <C.Button
          title="取得目前設定"
          onClick={() => {
            appCtx
              .fetchApi('get', `/api/v2/util/auth/device`)
              .then((result) => {
                if (result) {
                  alert(JSON.stringify(result));
                }
              });
          }}
        />
        <C.Button
          title="設備權限類別"
          onClick={() => {
            appCtx.setModal(<CC2.Type2 />);
          }}
        />
        <C.Button title="洗檔" />
        <a
          onClick={() => {
            appCtx
              .fetchApi('get', `/api/v2/util/auth/device-cfg-eg`)
              .then((result) => {
                if (result) {
                  alert(JSON.stringify(result));
                }
              });
          }}
        >
          {t('Example')}
        </a>
      </div>
    );
  };

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Authority')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          {data1 && (
            <C.Table2WithAction
              label={t('Page permission')}
              header={data1?.table?.header}
              data={data1?.table?.data}
              page_size={data1?.table?.page_size}
              filter_index={data1?.table?.filter_index}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT1
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT1
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/util/auth/webpage?id=${value.id}`,
                        value,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              addtionRow={<Ar1 />}
            />
          )}

          <div className="h-3" />
          <div className="h-3" />

          {data2 && (
            <C.Table2WithAction
              label={t('Equipment permission')}
              header={data2?.table?.header}
              data={data2?.table?.data}
              page_size={data2?.table?.page_size}
              filter_index={data2?.table?.filter_index}
              onNew={() => {
                appCtx.setModal(
                  <CC.NewT2
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onEdit={(value) => {
                appCtx.setModal(
                  <CC.EditT2
                    item={value}
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              onDelete={(value) => {
                appCtx.setModal(
                  <CD.DeleteMsgBox
                    onOK={async () => {
                      await appCtx.fetchApi(
                        'delete',
                        `/api/v2/util/auth/device?id=${value.id}`,
                        value,
                      );
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                );
              }}
              addtionRow={<Ar2 />}
            />
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
