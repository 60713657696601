import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export const SelectPanel = ({
  setSelectedDepartment,
  selectedStore,
  setSelectedStore,
  useVendor = false,
  selectedVendor,
  setSelectedVendor,
}: {
  selectedDepartment: string;
  setSelectedDepartment: (value: string) => void;
  selectedStore: string;
  setSelectedStore: (value: string) => void;
  useVendor?: boolean;
  selectedVendor?: string;
  setSelectedVendor?: (value: string) => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [departmentData, setDepartmentData] = React.useState<any>(null);

  const [store, setStore] = React.useState<any[]>([]);
  const [vendor, setVendor] = React.useState<any[]>([]);

  const [selected1, setSelected1] = React.useState<string>('');
  const [selected2, setSelected2] = React.useState<string>('');
  const [selected3, setSelected3] = React.useState<string>('');
  const [selected4, setSelected4] = React.useState<string>('');
  const [selected5, setSelected5] = React.useState<string>('');
  const [selected6, setSelected6] = React.useState<string>('');

  const [lastSelected, setLastSelected] = React.useState<{
    level: number;
    value: string;
  } | null>(null);

  const [department1, setDepartment1] = React.useState<any[]>([]);
  const [department2, setDepartment2] = React.useState<any[]>([]);
  const [department3, setDepartment3] = React.useState<any[]>([]);
  const [department4, setDepartment4] = React.useState<any[]>([]);
  const [department5, setDepartment5] = React.useState<any[]>([]);
  const [department6, setDepartment6] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (lastSelected === null) {
      return;
    }

    appCtx
      .fetchApi('get', `/api/v2/stores?depart=${lastSelected.value}`)
      .then((result) => {
        if (result) {
          setStore(
            result.data.map((item: any) => ({
              title: item.name,
              value: item.id,
            })),
          );
        }
      });

    useVendor &&
      appCtx
        .fetchApi(
          'get',
          `/api/v2/vendors?depart=${lastSelected.value}&type=all`,
        )
        .then((result) => {
          if (result) {
            setVendor(
              result.data.map((item: any) => ({
                title: item.name,
                value: item.id,
              })),
            );
          }
        });

    if (lastSelected.level === 1 && department2.length === 0) {
      setSelectedDepartment(lastSelected.value);
    }
    if (lastSelected.level === 2 && department3.length === 0) {
      setSelectedDepartment(lastSelected.value);
    }
    if (lastSelected.level === 3 && department4.length === 0) {
      setSelectedDepartment(lastSelected.value);
    }
    if (lastSelected.level === 4 && department5.length === 0) {
      setSelectedDepartment(lastSelected.value);
    }
    if (lastSelected.level === 5 && department6.length === 0) {
      setSelectedDepartment(lastSelected.value);
    }
    if (lastSelected.level === 6) {
      setSelectedDepartment(lastSelected.value);
    }
  }, [lastSelected]);

  // lv1
  React.useEffect(() => {
    if (selected1 === '') {
      return;
    }

    setSelected2('');
    setSelected3('');
    setSelected4('');
    setSelected5('');
    setSelected6('');
    setSelectedStore('');
    setSelectedVendor && setSelectedVendor('');

    setDepartment2(
      departmentData.tree.children
        ?.filter((item: any) => item.id == selected1)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment3([]);
    setDepartment4([]);
    setDepartment5([]);
    setDepartment6([]);

    setLastSelected({ value: selected1, level: 1 });
  }, [selected1]);

  // lv2
  React.useEffect(() => {
    if (selected2 === '') {
      return;
    }

    setSelected3('');
    setSelected4('');
    setSelected5('');
    setSelected6('');
    setSelectedStore('');
    setSelectedVendor && setSelectedVendor('');

    setDepartment3(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment4([]);
    setDepartment5([]);
    setDepartment6([]);

    setLastSelected({ value: selected2, level: 2 });
  }, [selected2]);

  // lv3
  React.useEffect(() => {
    if (selected3 === '') {
      return;
    }

    setSelected4('');
    setSelected5('');
    setSelected6('');
    setSelectedStore('');
    setSelectedVendor && setSelectedVendor('');

    setDepartment4(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.filter((item: any) => item.name == selected3)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment5([]);
    setDepartment6([]);

    setLastSelected({ value: selected3, level: 3 });
  }, [selected3]);

  // lv4
  React.useEffect(() => {
    if (selected4 === '') {
      return;
    }

    setSelected5('');
    setSelected6('');
    setSelectedStore('');
    setSelectedVendor && setSelectedVendor('');

    setDepartment5(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.filter((item: any) => item.name == selected3)[0]
        ?.children?.filter((item: any) => item.name == selected4)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment6([]);

    setLastSelected({ value: selected4, level: 4 });
  }, [selected4]);

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi('get', `/api/v2/department-map`).then((result) => {
        if (result) {
          setDepartmentData(result);
          setDepartment1(
            result.tree.children.map((item: any) => ({
              title: item.name,
              value: item.id,
            })),
          );
        }
      });
    }
  }, [appCtx.token]);

  return (
    <>
      <C.BlueLabel title={t('Select')} />
      <C.Select
        options={department1}
        value={selected1}
        onValueChange={setSelected1}
      />
      {department2.length > 0 && (
        <C.Select
          options={department2}
          value={selected2}
          onValueChange={setSelected2}
        />
      )}
      {department3.length > 0 && (
        <C.Select
          options={department3}
          value={selected3}
          onValueChange={setSelected3}
        />
      )}
      {department4.length > 0 && (
        <C.Select
          options={department4}
          value={selected4}
          onValueChange={setSelected4}
        />
      )}
      {department5.length > 0 && (
        <C.Select
          options={department5}
          value={selected5}
          onValueChange={setSelected5}
        />
      )}
      {department6.length > 0 && (
        <C.Select
          options={department6}
          value={selected6}
          onValueChange={setSelected6}
        />
      )}

      <C.Select
        options={store}
        value={selectedStore}
        onValueChange={setSelectedStore}
      />

      {useVendor && (
        <>
          <div className="w-full bg-gray-300" style={{ height: 1 }} />
          <C.Select
            options={vendor}
            value={selectedVendor!}
            onValueChange={setSelectedVendor!}
          />
        </>
      )}
    </>
  );
};
