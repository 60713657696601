import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export const M1 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any>(null);
  const [opt1, setOpt1] = React.useState<any[]>([]);
  const [opt2, setOpt2] = React.useState<any[]>([]);
  const [opt3, setOpt3] = React.useState<any[]>([]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/service/apply/info', {
        company: data.company,
        contact: data.contact,
        tel: data.tel,
        service_id: '1237',
        detail: {
          ttile: values.v1,
          subtype: { id: values.v2, tilte: 'sub2-2' },
        },
        point: values.v3,
      });
      onOK();
    },
  });

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/service/apply/info').then((result) => {
      if (result) {
        setData(result);

        setOpt1([
          { title: '請選擇', value: '' },
          ...result.services.map((item: any) => ({
            title: item.title,
            value: item.title,
          })),
        ]);

        setOpt2([{ title: '請選擇', value: '' }]);

        setOpt3([
          { title: '請選擇', value: '' },
          ...result.points.map((item: any) => ({ title: item, value: item })),
        ]);
      }
    });
  }, []);

  React.useEffect(() => {
    if (formik.values.v1) {
      const a = [
        ...data.services
          .filter((item: any) => item.title === formik.values.v1)[0]
          .subtype.map((item2: any) => {
            console.log(item2);
            return {
              title: item2.title,
              value: item2.id,
            };
          }),
      ];

      setOpt2([{ title: '請選擇', value: '' }, ...a]);
    }
  }, [formik.values.v1]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <div className="grid grid-cols-2 gap-3">
          <div>申請公司</div>
          <div>{'亞增'}</div>
          <div>聯絡人</div>
          <div>{'陳小明'}</div>
          <div>聯絡電話</div>
          <div>{'(02)1234-5678'}</div>
          <div>申請服務</div>
          <div>
            <select
              className="border border-gray-500"
              value={formik.values.v1}
              onChange={(evt) => formik.setFieldValue('v1', evt.target.value)}
            >
              {opt1.map((item: any) => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </div>
          <div></div>
          <div>
            <select
              className="border border-gray-500"
              value={formik.values.v2}
              onChange={(evt) => formik.setFieldValue('v2', evt.target.value)}
            >
              {opt2.map((item: any) => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </div>
          <div>申請點數</div>
          <div>
            <select
              className="border border-gray-500"
              value={formik.values.v3}
              onChange={(evt) => formik.setFieldValue('v3', evt.target.value)}
            >
              {opt3.map((item: any) => (
                <option value={item.value}>{item.title}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
