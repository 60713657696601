import React from 'react';
import { Pie as AntdPie } from '@ant-design/charts';

export interface PieProps {
  data: Array<{ type: string; value: number }>;
  width?: number;
  height?: number;
}

export const Pie = ({ data, width, height = 200 }: PieProps) => {
  var config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#E0E0E0', '#A977CF', '#7BC7CB', '#8DC97D'],
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
    legend: {
      itemName: {
        style: {
          fontSize: 16,
        },
      },
    },
  };

  // @ts-ignore
  return <AntdPie {...config} width={width} height={height} />;
};
