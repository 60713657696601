import { Menu } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface MenuItem {
  path: string | null;
  onClick?: () => void;
  title: string;
  padding?: boolean;
}

export const HeaderToolButton = ({
  icon,
  onClick,
  tooltip,
  menuItems,
  suffix,
}: {
  icon: string;
  onClick?: () => void;
  tooltip?: string;
  menuItems?: Array<MenuItem | undefined>;
  suffix?: string;
}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const ButtonContent = () => {
    return (
      <>
        {tooltip && <span className="tooltiptext">{tooltip}</span>}

        <div className="w-full h-full absolute bg-white opacity-0 group-hover:opacity-50" />

        {menuItems && (
          <i
            className="fa fa-chevron-down text-white opacity-50 absolute bottom-2 right-2"
            style={{ fontSize: 10 }}
          />
        )}

        <div className="flex justify-center items-center">
          <div className="w-16 flex justify-center items-center">
            <img src={icon} />
          </div>

          {suffix && <div className="text-white font-bold mr-5">{suffix}</div>}
        </div>
      </>
    );
  };

  if (!menuItems) {
    return (
      <button
        className="focus:outline-none group h-14 flex justify-center items-center relative tooltip"
        onClick={onClick}
      >
        <ButtonContent />
      </button>
    );
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="focus:outline-none group h-14 flex justify-center items-center relative tooltip">
        <ButtonContent />
      </Menu.Button>

      <Menu.Items
        className="absolute left-0 w-52 bg-white flex flex-col z-50"
        style={{ marginTop: 1 }}
      >
        {menuItems?.map((item) => {
          if (item === undefined) {
            return <div></div>;
          }

          if (item.path === null && item.onClick) {
            return (
              <Menu.Item onClick={item.onClick}>
                {({ active }) => (
                  <div
                    className={`cursor-pointer px-5 py-2 text-base ${
                      active ? 'bg-white text-black' : 'bg-bar text-white'
                    } ${item.padding ? 'pl-10' : ''}`}
                    style={{ backgroundColor: active ? 'white' : '#90AEC6' }}
                  >
                    {item.title}
                  </div>
                )}
              </Menu.Item>
            );
          } else if (item.path === null) {
            return (
              <div
                className={`px-5 py-2 text-base text-white`}
                style={{ backgroundColor: '#6790B1' }}
              >
                {item.title}
              </div>
            );
          } else {
            return (
              <Menu.Item onClick={() => history.push(item.path!)}>
                {({ active }) => (
                  <div
                    className={`cursor-pointer px-5 py-2 text-base ${
                      active ? 'bg-white text-black' : 'bg-bar text-white'
                    } ${item.padding ? 'pl-10' : ''}`}
                    style={{ backgroundColor: active ? 'white' : '#90AEC6' }}
                  >
                    {item.title}
                  </div>
                )}
              </Menu.Item>
            );
          }
        })}
      </Menu.Items>
    </Menu>
  );
};
