// done
// removed

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current="通知" />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          <div className="flex flex-col space-x-3 mb-3">
            <C.Divider title="通知對象" />
          </div>

          <div className="flex space-x-2 mb-3">
            <div className="flex-1" />
            <C.Button title="+" />
          </div>

          <C.Table
            columns={[
              { title: '部門', dataIndex: '' },
              { title: '職稱', dataIndex: '' },
              { title: '姓名', dataIndex: '' },
              { title: 'Amber', dataIndex: '' },
              { title: '電話', dataIndex: '' },
              { title: 'email', dataIndex: '' },
              { title: '有效期限', dataIndex: '' },
              { title: '驗證', dataIndex: '' },
              { title: '驗證信', dataIndex: '' },
              { title: '備註', dataIndex: '' },
              { title: '動作', dataIndex: '' },
            ]}
          />
        </C.ContentPanel>
      </div>
    </div>
  );
};
