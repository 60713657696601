import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface ButtonProps {
  className?: string;
  title: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button = ({
  className,
  title,
  onClick,
  disabled,
}: ButtonProps) => {
  const { t, i18n } = useTranslation();

  return (
    <button
      className={clsx(
        'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
        className,
        disabled && 'opacity-50',
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="text-white text-base">{t(title)}</div>
    </button>
  );
};
