import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import { HeaderToolButton } from './HeaderToolButton';
import { AppContext } from '../../AppContext';

export interface HeaderProps {
  className?: string;
  title: string;
  account: string;
}

export interface SubHeaderProps {
  className?: string;
  title: string;
  account: string;
  pageAuth: any;
}

export const Header = ({ title, account }: HeaderProps) => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [pageAuth, setPageAuth] = React.useState<any>({});

  React.useEffect(() => {
    if (appCtx.token) {
      const _pageAuth = JSON.parse(atob(appCtx.token.split('.')[1])).page_auth;
      setPageAuth(_pageAuth);
      // alert(JSON.stringify(_pageAuth));
    }
  }, [appCtx.token]);

  return (
    <>
      <HeaderFull
        title={title}
        account={account}
        className="hidden md:flex"
        pageAuth={pageAuth}
      />
      <HeaderSimple
        title={title}
        account={account}
        className="flex md:hidden"
        pageAuth={pageAuth}
      />
    </>
  );
};

const HeaderFull = ({
  className,
  title,
  account,
  pageAuth,
}: SubHeaderProps) => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  pageAuth['3-1'] = pageAuth['3-2'] = pageAuth['3-3'] = pageAuth['3-4'] = true;

  pageAuth['4-1'] =
    pageAuth['4-2'] =
    pageAuth['4-3'] =
    pageAuth['4-4'] =
    pageAuth['4-5'] =
    pageAuth['4-6'] =
      true;

  const part1Visible =
    pageAuth['1-1'] ||
    pageAuth['1-2'] ||
    pageAuth['1-3'] ||
    pageAuth['1-4'] ||
    pageAuth['1-5'] ||
    pageAuth['1-7'] ||
    pageAuth['1-8'];

  const part2Visible =
    pageAuth['2-1-1'] ||
    pageAuth['2-1-2'] ||
    pageAuth['2-1-3'] ||
    pageAuth['2-2-1'] ||
    pageAuth['2-2-2'] ||
    pageAuth['2-2-3'] ||
    pageAuth['2-2-4'] ||
    pageAuth['2-2-5'] ||
    pageAuth['2-3-1'] ||
    pageAuth['2-3-2'] ||
    pageAuth['2-3-3'] ||
    pageAuth['2-4'];

  const part3Visible =
    pageAuth['3-1'] || pageAuth['3-2'] || pageAuth['3-3'] || pageAuth['3-4'];

  const part4Visible =
    pageAuth['4-1'] ||
    pageAuth['4-2'] ||
    pageAuth['4-3'] ||
    pageAuth['4-4'] ||
    pageAuth['4-5'] ||
    pageAuth['4-6'];

  const part5Visible = pageAuth['5-1'] || pageAuth['5-2'];

  return (
    <div
      className={clsx(
        'flex items-center space-x-4 px-5 bg-bar z-50 fixed top-0 left-0 right-0',
        className,
      )}
    >
      {/* tool bar */}
      <div className="flex flex-1">
        <HeaderToolButton
          icon="/icons/home.svg"
          tooltip={t('Home')}
          onClick={() => history.push('/')}
        />
        {part1Visible && (
          <HeaderToolButton
            icon="/icons/chart.svg"
            tooltip={t('Data analysis')}
            menuItems={[
              pageAuth['1-1']
                ? {
                    path: '/DeviceConnectionRate',
                    title: t('Device connection rate'),
                  }
                : undefined,
              pageAuth['1-2']
                ? {
                    path: '/TemperatureAssuranceManagement',
                    title: t('Temperature product protection management'),
                  }
                : undefined,
              ,
              pageAuth['1-3']
                ? {
                    path: '/FreezerHealthDistribution',
                    title: t('Freezer health distribution'),
                  }
                : undefined,
              ,
              pageAuth['1-4']
                ? {
                    path: '/ElectricPowerRequirement',
                    title: t('Electricity requirements control'),
                  }
                : undefined,
              ,
              pageAuth['1-5']
                ? {
                    path: '/ElectricalConsumptionComparison',
                    title: t('Electrical comparison'),
                  }
                : undefined,
              ,
              pageAuth['1-6']
                ? {
                    path: '/OperationalAnalysis',
                    title: t('Operation analysis'),
                  }
                : undefined,
              ,
              pageAuth['1-7']
                ? { path: '/AlarmRecord', title: t('Report record') }
                : undefined,
              ,
            ]}
          />
        )}
        {part2Visible && (
          <HeaderToolButton
            icon="/icons/menu.svg"
            tooltip={t('Form setting')}
            menuItems={[
              { path: null, title: t('Download') },
              pageAuth['2-1-1']
                ? {
                    path: '/DownloadSenseData',
                    title: t('Sensor information'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-1-2']
                ? {
                    path: '/DownloadQualityAssuranceData',
                    title: t('Insured'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-1-3']
                ? {
                    path: '/DownloadLogData',
                    title: t('Log file'),
                    padding: true,
                  }
                : undefined,
              { path: null, title: t('Settings') },
              pageAuth['2-2-1']
                ? {
                    path: '/AlarmThresholdSetting',
                    title: t('Set the threshold'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-2-2']
                ? {
                    path: '/EarlyWarningSetting',
                    title: t('Warning'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-2-3']
                ? {
                    path: '/UserRoleSetting',
                    title: t('Authority'),
                    padding: true,
                  }
                : undefined,
              ,
              pageAuth['2-2-4']
                ? {
                    path: '/OrganizationSetting',
                    title: t('Organization'),
                    padding: true,
                  }
                : undefined,
              { path: null, title: t('Expansion') },
              pageAuth['2-3-1']
                ? {
                    path: '/InstructionListExpansion',
                    title: t('Instruction table'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-3-2']
                ? {
                    path: '/AppendPollingControlExpansion',
                    title: t('ApendPoll Management'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-3-3']
                ? {
                    path: '/SingleExecutionExpansion',
                    title: t('Single execution'),
                    padding: true,
                  }
                : undefined,
              pageAuth['2-4']
                ? { path: '/UpdateOperation', title: t('Update job') }
                : undefined,
            ]}
          />
        )}
        {part3Visible && (
          <HeaderToolButton
            icon="/icons/pencil.svg"
            tooltip={t('Network management')}
            menuItems={[
              pageAuth['3-1']
                ? { path: '/_31_', title: t('Distal operation') }
                : undefined,
              pageAuth['3-2']
                ? { path: '/_32_', title: t('Equipment Overview') }
                : undefined,
              pageAuth['3-3']
                ? { path: '/_33_', title: t('Energy-saving management') }
                : undefined,
              pageAuth['3-4']
                ? { path: '/_34_', title: t('Amber通知管理') }
                : undefined,
            ]}
          />
        )}
        {part4Visible && (
          <HeaderToolButton
            icon="/icons/setting.svg"
            tooltip={t('Maintenance')}
            menuItems={[
              pageAuth['4-1']
                ? { path: '/_41_OnlineMaintenance', title: '線上檢修-TGT' }
                : undefined,
              pageAuth['4-2']
                ? { path: '/_42_', title: '線上檢修-傳訊博士' }
                : undefined,
              pageAuth['4-3'] ? { path: '/_43_', title: '保養' } : undefined,
              pageAuth['4-4'] ? { path: '/_44_', title: '維修' } : undefined,
              pageAuth['4-5']
                ? { path: '/_45_', title: '系統訊息通知紀錄' }
                : undefined,
              pageAuth['4-6']
                ? { path: '/_46_', title: '發送訊息' }
                : undefined,
            ]}
          />
        )}
        <HeaderToolButton
          icon="/icons/global.svg"
          tooltip={t('Multi-language')}
          menuItems={appCtx.config.languages.map((lang) => ({
            path: null,
            title: lang.title,
            onClick: () => appCtx.setLanguage(lang.value),
          }))}
        />
        {part5Visible && (
          <HeaderToolButton
            icon="/icons/question.svg"
            tooltip={t('Support')}
            menuItems={[
              pageAuth['5-1'] ? { path: '/_51_', title: t('FAQ') } : undefined,
              pageAuth['5-2'] ? { path: '/_52_', title: t('關於') } : undefined,
            ]}
          />
        )}
      </div>

      {/* title */}
      <div className="text-white font-bold text-2xl">{title}</div>

      {/* account */}
      <div className="flex flex-1 items-center justify-end">
        <HeaderToolButton
          icon="/icons/person.svg"
          suffix={account}
          onClick={() => history.push('/Account')}
        />
      </div>
    </div>
  );
};

const HeaderSimple = ({
  className,
  title,
  account,
  pageAuth,
}: SubHeaderProps) => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [drawerVisible, setDrawerVisible] = React.useState(false);

  const part1Visible =
    pageAuth['1-1'] ||
    pageAuth['1-2'] ||
    pageAuth['1-3'] ||
    pageAuth['1-4'] ||
    pageAuth['1-5'] ||
    pageAuth['1-7'] ||
    pageAuth['1-8'];

  const part2Visible =
    pageAuth['2-1-1'] ||
    pageAuth['2-1-2'] ||
    pageAuth['2-1-3'] ||
    pageAuth['2-2-1'] ||
    pageAuth['2-2-2'] ||
    pageAuth['2-2-3'] ||
    pageAuth['2-2-4'] ||
    pageAuth['2-2-5'] ||
    pageAuth['2-3-1'] ||
    pageAuth['2-3-2'] ||
    pageAuth['2-3-3'] ||
    pageAuth['2-4'];

  const part3Visible =
    pageAuth['3-1'] || pageAuth['3-2'] || pageAuth['3-3'] || pageAuth['3-4'];

  const part4Visible =
    pageAuth['4-1'] ||
    pageAuth['4-2'] ||
    pageAuth['4-3'] ||
    pageAuth['4-4'] ||
    pageAuth['4-5'] ||
    pageAuth['4-6'];

  const part5Visible = pageAuth['5-1'] || pageAuth['5-2'];

  const HB = ({
    className,
    href,
    onClick,
    children,
  }: {
    className: string;
    href: string;
    onClick?: () => void;
    children: React.ReactNode;
  }) => {
    if (href) {
      return (
        <div
          className={clsx('cursor-pointer my-1', className)}
          onClick={() => {
            history.push(href);
            setDrawerVisible(false);
          }}
        >
          {children}
        </div>
      );
    } else if (onClick) {
      return (
        <div
          className={clsx('cursor-pointer my-1', className)}
          onClick={() => {
            onClick();
            setDrawerVisible(false);
          }}
        >
          {children}
        </div>
      );
    } else {
      return <div className={clsx('my-1', className)}>{children}</div>;
    }
  };

  return (
    <div
      className={clsx(
        'flex items-center space-x-4 px-5 bg-bar z-50 fixed top-0 left-0 right-0',
        className,
      )}
    >
      {/* title */}
      <div className="text-white font-bold text-2xl my-3">{title}</div>

      <div className="flex-1" />

      <div
        className="block md:hidden cursor-pointer"
        onClick={() => setDrawerVisible(true)}
      >
        <i className="fa fa-bars text-white" />
      </div>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        drawerStyle={{ background: '#6890B1' }}
        width="100%"
        // height="100%"
      >
        {/* <div
          className={clsx('flex items-center space-x-4 px-5 bg-bar', className)}
        >
          <div className="text-white font-bold text-2xl my-3">{title}</div>

          <div className="flex-1" />

          <div
            className="block md:hidden cursor-pointer"
            onClick={() => setDrawerVisible(false)}
          >
            <i className="fa fa-bars text-white" />
          </div>
        </div> */}

        <div className="overflow-scrolla">
          <div className="text-white text-2xl">{title}</div>
          <Collapse defaultActiveKey={['1']} ghost>
            <Collapse.Panel
              header={<span className="text-white">{t('Home')}</span>}
              key="1"
            >
              <div className="flex flex-col ml-6">
                <HB href="#" className="text-white">
                  {t('Home')}
                </HB>
              </div>
            </Collapse.Panel>
            {part1Visible && (
              <Collapse.Panel
                header={
                  <span className="text-white">{t('Data analysis')}</span>
                }
                key="2"
              >
                <div className="flex flex-col ml-6">
                  <HB href="/DeviceConnectionRate" className="text-white">
                    {t('Device connection rate')}
                  </HB>
                  <HB
                    href="/TemperatureAssuranceManagement"
                    className="text-white"
                  >
                    {t('Temperature product protection management')}
                  </HB>
                  <HB href="/FreezerHealthDistribution" className="text-white">
                    {t('Freezer health distribution')}
                  </HB>
                  <HB href="/ElectricPowerRequirement" className="text-white">
                    {t('Electricity requirements control')}
                  </HB>
                  <HB
                    href="/ElectricalConsumptionComparison"
                    className="text-white"
                  >
                    {t('Electrical comparison')}
                  </HB>
                  <HB href="/OperationalAnalysis" className="text-white">
                    {t('Operation analysis')}
                  </HB>
                  <HB href="/AlarmRecord" className="text-white">
                    {t('Report record')}
                  </HB>
                </div>
              </Collapse.Panel>
            )}
            {part2Visible && (
              <Collapse.Panel
                header={<span className="text-white">{t('Form setting')}</span>}
                key="3"
              >
                <div className="flex flex-col ml-6">
                  <HB href="" className="text-white opacity-50">
                    {t('Download')}
                  </HB>
                  <HB href="/DownloadSenseData" className="text-white">
                    {t('Sensor information')}
                  </HB>
                  <HB
                    href="/DownloadQualityAssuranceData"
                    className="text-white"
                  >
                    {t('Insured')}
                  </HB>
                  <HB href="/DownloadLogData" className="text-white">
                    {t('Log file')}
                  </HB>
                  <HB href="" className="text-white opacity-50">
                    {t('Settings')}
                  </HB>
                  <HB href="/AlarmThresholdSetting" className="text-white">
                    {t('Set the threshold')}
                  </HB>
                  <HB href="/EarlyWarningSetting" className="text-white">
                    {t('Warning')}
                  </HB>
                  <HB href="/UserRoleSetting" className="text-white">
                    {t('Authority')}
                  </HB>
                  <HB href="/OrganizationSetting" className="text-white">
                    {t('Organization')}
                  </HB>
                  <HB href="" className="text-white opacity-50">
                    {t('Expansion')}
                  </HB>
                  <HB href="/InstructionListExpansion" className="text-white">
                    {t('Instruction table')}
                  </HB>
                  <HB
                    href="/AppendPollingControlExpansion"
                    className="text-white"
                  >
                    {t('ApendPoll Management')}
                  </HB>
                  <HB href="/SingleExecutionExpansion" className="text-white">
                    {t('Single execution')}
                  </HB>
                  <HB href="/UpdateOperation" className="text-white">
                    {t('Update job')}
                  </HB>
                </div>
              </Collapse.Panel>
            )}
            {part3Visible && (
              <Collapse.Panel
                header={
                  <span className="text-white">{t('Network management')}</span>
                }
                key="4"
              >
                <div className="flex flex-col ml-6">
                  <HB href="_31_" className="text-white">
                    {t('Distal operation')}
                  </HB>
                  <HB href="/_32_" className="text-white">
                    {t('Equipment Overview')}
                  </HB>
                  <HB href="/_33_" className="text-white">
                    {t('Energy-saving management')}
                  </HB>
                  <HB href="/_34_" className="text-white">
                    {t('Amber通知管理')}
                  </HB>
                </div>
              </Collapse.Panel>
            )}
            {part4Visible && (
              <Collapse.Panel
                header={<span className="text-white">{t('Maintenance')}</span>}
                key="5"
              >
                <div className="flex flex-col ml-6">
                  <HB href="/_41_OnlineMaintenance" className="text-white">
                    線上檢修-TGT
                  </HB>
                  <HB href="/_42_" className="text-white">
                    線上檢修-傳訊博士
                  </HB>
                  <HB href="/_43_" className="text-white">
                    保養
                  </HB>
                  <HB href="/_44_" className="text-white">
                    維修
                  </HB>
                  <HB href="/_45_" className="text-white">
                    系統訊息通知紀錄
                  </HB>
                  <HB href="/_46_" className="text-white">
                    發送訊息
                  </HB>
                </div>
              </Collapse.Panel>
            )}
            <Collapse.Panel
              header={<span className="text-white">{t('Multi-language')}</span>}
              key="6"
            >
              <div className="flex flex-col ml-6">
                {appCtx.config.languages.map((lang) => {
                  return (
                    <HB
                      href=""
                      onClick={() => appCtx.setLanguage(lang.value)}
                      className="text-white"
                    >
                      {lang.title}
                    </HB>
                  );
                })}
              </div>
            </Collapse.Panel>
            {part5Visible && (
              <Collapse.Panel
                header={<span className="text-white">{t('Support')}</span>}
                key="7"
              >
                <div className="flex flex-col ml-6">
                  <HB href="/_51_" className="text-white">
                    {t('FAQ')}
                  </HB>
                  <HB href="/_52_" className="text-white">
                    {t('關於')}
                  </HB>
                </div>
              </Collapse.Panel>
            )}
          </Collapse>
          <div
            className="text-white text-center"
            onClick={() => setDrawerVisible(false)}
          >
            {t('Close')}
          </div>
        </div>
      </Drawer>
    </div>
  );
};
