import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { AppProvider } from './AppContext';
import * as P from './pages';

export default () => {
  return (
    <AppProvider>
      <Router>
        <Switch>
          <Route path={'/'} exact={true} component={P.Home} />
          {Object.entries(P).map((item) => (
            <Route path={'/' + item[0]} component={item[1]} />
          ))}
        </Switch>
      </Router>
    </AppProvider>
  );
};
