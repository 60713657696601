import React from 'react';
import * as antd from 'antd';
import { useTranslation } from 'react-i18next';

export interface SidePanelProps {
  children?: React.ReactNode;
}

export const SidePanel = ({ children }: SidePanelProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className="p-5 flex flex-col space-y-3 w-full md:w-80 md:side-panel-height"
      style={{ backgroundColor: '#E6E6E6' }}
    >
      {children}
    </div>
  );
};
