//

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './inputs/Button';
import { BlueLabel } from './layout/BlueLabel';

import moment from 'moment';

interface Table2Props {
  label: string;
  header: any;
  data: any[];
  page_size?: number;
  filter_index?: number;
  showTitle?: boolean;
  onSelected?: (value: any) => void;
}

export const Table2 = ({
  label,
  header,
  data,
  page_size = 10,
  filter_index = -1,
  showTitle = true,
  onSelected,
}: Table2Props) => {
  const [filter, setFilter] = React.useState<string[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const { t, i18n } = useTranslation();

  const downloadCSV = () => {
    const h = header?.value.join(',') + '\n';
    const b = data
      ?.map((dataItem) => {
        return dataItem?.value
          ?.map((column: string) => {
            if (moment(column, moment.ISO_8601, true).isValid()) {
              return moment(column).format('YYYY-MM-DD HH:mm:ss');
            }
            return column;
          })
          .join(',');
      })
      .join('\n');

    const encodedUri =
      'data:text/csv;charset=utf8,' + encodeURIComponent('\uFEFF' + h + b);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
  };

  const Filter = () => {
    if (!filter_index || filter_index < 0) {
      return null;
    } else {
      let s: string[] = [];

      data?.map((dataItem, index) => {
        return dataItem?.value?.map((column: string, index: number) => {
          if (index === filter_index) {
            s.push(column);
          }

          return null;
        });
      });

      let uniqueItems = [...new Set<string>(s)];

      return (
        <>
          {uniqueItems.map((item) => {
            return (
              <>
                <input
                  type="checkbox"
                  className="w-5 h-5"
                  checked={filter.includes(item)}
                  onChange={(evt) => {
                    if (evt.target.checked) {
                      setFilter([...filter, item]);
                    } else {
                      setFilter(filter.filter((i) => i !== item));
                    }
                  }}
                />
                <div>{item}</div>
              </>
            );
          })}
        </>
      );
    }
  };

  const totalPages = Math.ceil(
    data?.filter((dataItem) => {
      if (filter.length > 0) {
        return filter.includes(dataItem?.value?.[filter_index!]);
      }

      return true;
    })?.length / page_size,
  );

  return (
    <>
      <div className="flex space-x-2 mb-3">
        {showTitle && <BlueLabel title={label} />}
      </div>

      <div className="flex items-center space-x-2 mb-3">
        <div className="flex-1" />
        <Button title={t('Form download')} onClick={() => downloadCSV()} />

        <Filter />

        <Button
          title={t('Previous page')}
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage <= 1}
        />

        {Array(totalPages)
          .fill(0)
          .map((_, index) => {
            if (
              currentPage === 1 &&
              (index + 1 < currentPage - 2 || index + 1 > currentPage + 4)
            ) {
              return null;
            } else if (
              currentPage === 2 &&
              (index + 1 < currentPage - 2 || index + 1 > currentPage + 3)
            ) {
              return null;
            } else if (
              currentPage === totalPages - 1 &&
              (index + 1 < currentPage - 3 || index + 1 > currentPage + 2)
            ) {
              return null;
            } else if (
              currentPage === totalPages &&
              (index + 1 < currentPage - 4 || index + 1 > currentPage + 2)
            ) {
              return null;
            } else if (
              currentPage !== 1 &&
              currentPage !== 2 &&
              currentPage !== totalPages &&
              currentPage !== totalPages - 1 &&
              (index + 1 < currentPage - 2 || index + 1 > currentPage + 2)
            ) {
              return null;
            }

            return (
              <Button
                title={`${index + 1}`}
                onClick={() => setCurrentPage(index + 1)}
                disabled={currentPage === index + 1}
              />
            );
          })}

        <Button
          title={t('Next page')}
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage >= totalPages}
        />
      </div>

      <table className="w-full text-center">
        <thead>
          <tr>
            {header?.value?.map((column: string, index: number) => {
              return (
                <th
                  key={index}
                  style={{
                    backgroundColor: header?.bg_color?.[index] ?? '#999999',
                    color: header?.txt_color?.[index] ?? 'white',
                  }}
                >
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data
            ?.filter((dataItem) => {
              if (filter.length > 0) {
                return filter.includes(dataItem?.value?.[filter_index!]);
              }

              return true;
            })
            ?.slice((currentPage - 1) * page_size, currentPage * page_size)
            ?.map((dataItem, index) => {
              return (
                <tr
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    onSelected && onSelected(data?.[index]);
                  }}
                >
                  {dataItem?.value?.map((column: string, index: number) => {
                    if (moment(column, moment.ISO_8601, true).isValid()) {
                      return (
                        <td key={index} style={{ backgroundColor: 'white' }}>
                          {moment(column).format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                      );
                    }

                    return (
                      <td key={index} style={{ backgroundColor: 'white' }}>
                        {column}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};
