import React from 'react';
import { useTranslation } from 'react-i18next';

import { RingProgress } from '../chart/RingProgress';

export interface ChartPanelProps {
  title: string;
  onDetailClick?: () => void;
  charts?: React.ReactNode;
  scrollable?: boolean;
}

export const ChartPanel = ({
  title,
  onDetailClick,
  charts,
  scrollable = false,
}: ChartPanelProps) => {
  const { t, i18n } = useTranslation();
  const [position, setPosition] = React.useState(0);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="w-full p-5" style={{ backgroundColor: '#EEEEEE' }}>
      <div className="flex items-center">
        <div className="text-bar text-xl font-bold">-{title}</div>

        <div
          className="ml-4 text-base underline cursor-pointer"
          onClick={onDetailClick}
        >
          {t('Detail')}
        </div>
      </div>

      <div className="flex justify-center items-center space-x-5 mt-5">
        {scrollable && (
          <div
            onClick={() => {
              ref.current?.scrollBy({ left: -20, behavior: 'smooth' });
            }}
          >
            <i
              className="fa fa-play transform rotate-180"
              style={{ color: 'gray', fontSize: 30 }}
            />
          </div>
        )}
        {scrollable && (
          <div
            ref={ref}
            className="flex-1 flex overflow-hidden space-x-3 justify-around"
          >
            {charts}
          </div>
        )}
        {scrollable && (
          <div
            onClick={() => {
              ref.current?.scrollBy({ left: 20, behavior: 'smooth' });
            }}
          >
            <i className="fa fa-play" style={{ color: 'gray', fontSize: 30 }} />
          </div>
        )}

        {!scrollable && charts}
      </div>
    </div>
  );
};
