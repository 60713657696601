import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data1_1, setData1_1] = React.useState<any>(null);
  const [data1_2, setData1_2] = React.useState<any>(null);
  const [data1_3, setData1_3] = React.useState<any>(null);
  const [data1_4, setData1_4] = React.useState<any>(null);
  const [data1_5, setData1_5] = React.useState<any>(null);
  const [data1_7, setData1_7] = React.useState<any>(null);
  const [data1_8, setData1_8] = React.useState<any>(null);

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx
        .fetchApi('get', '/api/v2/dashboard/iot-connection')
        .then((result) => setData1_1(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/qa-temp')
        .then((result) => setData1_2(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/health')
        .then((result) => setData1_3(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/alarm-record')
        .then((result) => setData1_4(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/operation')
        .then((result) => setData1_5(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/pwr-ctrl')
        .then((result) => setData1_7(result));

      appCtx
        .fetchApi('get', '/api/v2/dashboard/pwr-compare')
        .then((result) => setData1_8(result));
    }
  }, [appCtx.token]);

  const lineGraphData4E = (_data: any): any[] => {
    const groups = _data.chart.legend.value;

    let array = [];
    for (const _d of _data.chart.data) {
      for (let j = 0; j < _d.value.length; j++) {
        array.push({
          name: groups[j],
          xData: _d.title,
          yData: _d.value[j],
        });
      }
    }

    return array;
  };

  const lineGraphData = (_data: any): any[] => {
    const groups = _data.news.map((item: any) => item.value);

    let array = [];
    for (const _d of _data.chart.data) {
      for (let j = 0; j < _d.value.length; j++) {
        array.push({
          name: groups[j],
          xData: _d.title,
          yData: _d.value[j],
        });
      }
    }

    return array;
  };

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[]} current={t('Home')} />

      <div className="px-5 grid grid-cols-1 lg:grid-cols-4 gap-2">
        <div className="col-span-1 lg:col-span-2">
          <C.ChartPanel
            title={t('Device connection rate')}
            onDetailClick={() => history.push('/DeviceConnectionRate')}
            charts={
              data1_1 &&
              data1_1.data.map((item: any) => {
                return (
                  <C.RingProgress
                    title={item.title}
                    percent={Number(item.value.replace('%', '')) / 100}
                  />
                );
              })
            }
            scrollable
          />
        </div>

        <C.ChartPanel
          title={t('Temperature product protection management')}
          onDetailClick={() => history.push('/TemperatureAssuranceManagement')}
          charts={
            data1_2 && (
              <C.Pie
                data={data1_2.chart.data.map((item: any) => ({
                  type: item.title,
                  value: item.value,
                }))}
                width={300}
              />
            )
          }
        />

        <C.ChartPanel
          title={t('Freezer health distribution')}
          onDetailClick={() => history.push('/FreezerHealthDistribution')}
          charts={
            data1_3 && (
              <C.Pie
                data={data1_3.chart.data.map((item: any) => ({
                  type: item.title,
                  value: item.value,
                }))}
                width={300}
              />
            )
          }
        />

        <C.ChartPanel
          title={t('Electricity requirements control')}
          onDetailClick={() => history.push('/ElectricPowerRequirement')}
          charts={
            data1_7 && (
              <C.Column
                width={300}
                data={lineGraphData4E(data1_7)}
                seriesField="name"
                xField="xData"
                yField="yData"
              />
            )
          }
        />

        <C.ChartPanel
          title={t('Electrical comparison')}
          onDetailClick={() => history.push('/ElectricalConsumptionComparison')}
          charts={
            data1_8 && (
              <C.Column
                width={300}
                data={lineGraphData4E(data1_8)}
                seriesField="name"
                xField="xData"
                yField="yData"
              />
            )
          }
        />

        <C.ChartPanel
          title={t('Operation analysis')}
          onDetailClick={() => history.push('/OperationalAnalysis')}
          charts={
            data1_5 && (
              <C.Pie
                data={data1_5.chart.data.map((item: any) => ({
                  type: item.title,
                  value: item.value,
                }))}
                width={300}
              />
            )
          }
        />

        <C.ChartPanel
          title={t('Report record')}
          onDetailClick={() => history.push('/AlarmRecord')}
          charts={
            data1_4 && (
              <C.Column
                width={300}
                data={lineGraphData(data1_4)}
                seriesField="name"
                xField="xData"
                yField="yData"
              />
            )
          }
        />
      </div>
    </div>
  );
};
