import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [progressOptions, setProgressOptions] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      start: '',
      end: '',
      progress: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      start: Yup.string().required(t('Required')),
      end: Yup.string().required(t('Required')),
      progress: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      const _start = moment(values.start).format('YYYY-MM-DDTHH:mm:ss');
      const _end = moment(values.end).format('YYYY-MM-DDTHH:mm:ss');

      appCtx
        .fetchApi2(
          'get',
          `/api/v2/fix/cases?depart-id=${values.department}&start=${_start}&end=${_end}&progress-id=${values.progress}&page=1`,
        )
        .then((result) => {
          if (result) {
            const rows = result.records.map((item: any) => ({
              id: item.id,
              value: ['', '', '', '', '', '', '', '', ''],
            }));

            setData({
              table: {
                lable: '',
                header: {
                  value: [
                    '單號',
                    '報修時間',
                    '原始店號',
                    '店舖',
                    '設備',
                    '溫域',
                    '故障描述',
                    'ID',
                    '目前進度',
                  ],
                  bg_color: Array(rows.length).fill('#999999'),
                  txt_color: Array(rows.length).fill('#FFFFFF'),
                },
                data: rows,
                page_size: 10,
                filter_index: -1,
              },
            });
          }
        });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi2('get', `/api/v2/fix/progresses`).then((result) =>
        setProgressOptions(
          result.progresses.map((item: any) => ({
            title: item.title,
            value: item.id,
          })),
        ),
      );
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'維修進度回報'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇組織'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <C.BlueLabel title={'步驟2. 選擇時間'} />
          <C.DatePicker
            value={formik.values.start}
            onValueChange={(value) => formik.setFieldValue('start', value)}
          />
          <C.DatePicker
            value={formik.values.end}
            onValueChange={(value) => formik.setFieldValue('end', value)}
          />
          <div className="h-3" />

          <C.BlueLabel title={'步驟3. 選擇進度'} />
          <C.Select
            options={progressOptions}
            value={formik.values.progress}
            onValueChange={(value) => formik.setFieldValue('progress', value)}
          />
          <div className="h-3" />

          <C.Button
            title={'搜尋'}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel fixedHeight>
          <ReflexContainer orientation="horizontal">
            <ReflexElement flex={2}>
              <C.BlueLabel title="報修案件" />
              {data && (
                <C.Table2
                  label={data?.table?.title}
                  header={data?.table?.header}
                  data={data?.table?.data}
                  page_size={data?.table?.page_size}
                  filter_index={data?.table?.filter_index}
                  showTitle={false}
                />
              )}
            </ReflexElement>

            {/* splitter */}
            <ReflexSplitter
              className="my-5"
              style={{
                backgroundColor: 'rgb(104,144,177)',
                border: 'none',
                height: 1,
              }}
            />

            <ReflexElement flex={1}>
              <C.BlueLabel title={'維修紀錄'} />

              <div className="flex flex-col w-1/2 space-y-3 mb-5">
                <C.Select
                  options={progressOptions}
                  value=""
                  // value={formik.values.progress}
                  // onValueChange={(value) => formik.setFieldValue('progress', value)}
                />
                <C.TextInput value="" placeholder="備註" />

                <div className="flex justify-end">
                  <C.Button title={t('Renew')} onClick={() => {}} />
                </div>

                <C.SelectableList data={[]} value="" />
              </div>

              {/* <C.BlueLabel title="保養紀錄" /> */}
              {data && (
                <C.ComponentSlider>
                  {Array.from({ length: 10 }).map((_, i) => (
                    <C.Card1
                      img="sss"
                      date="dsads"
                      desc="dfsfsdfsdf"
                      onDelete={() => {}}
                    />
                  ))}

                  <div
                    className="w-20 h-20 bg-button flex justify-center items-center cursor-pointer"
                    style={{ minWidth: 80 }}
                  >
                    <i
                      className="fa fa-plus"
                      style={{ color: 'white', fontSize: 30 }}
                    />
                  </div>
                </C.ComponentSlider>
              )}
            </ReflexElement>
          </ReflexContainer>
        </C.ContentPanel>
      </div>
    </div>
  );
};
