import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);
  const [data3, setData3] = React.useState<any>(null);

  const [selectedStoreId, setSelectedStoreId] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      start: '',
      end: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      start: Yup.string().required(t('Required')),
      end: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      // 1
      appCtx
        .fetchApi('get', `/api/v2/stores?depart=${values.department}`)
        .then((result) => {
          if (result) {
            setData(result.data);
          }
        });
    },
  });

  React.useEffect(() => {
    if (selectedStoreId) {
      // 2
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/store/info/engineers?storeid=${selectedStoreId}`,
        )
        .then((result) => {
          if (result) {
            setData2(result.engineers);
          }
        });

      // 3
      appCtx
        .fetchApi(
          'get',
          `/api/v2/store/service?storeid=${selectedStoreId}&serviceid=7&start=${moment(
            formik.values.start,
          ).format('YYYY-MM-DDTHH:mm:ss')}&end=${moment(
            formik.values.end,
          ).format('YYYY-MM-DDTHH:mm:ss')}`,
        )
        .then((result) => {
          if (result) {
            setData3(result.services);
          }
        });
    }
  }, [selectedStoreId]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'Amber通知管理'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇門市'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <C.BlueLabel title={'步驟2. 開始時間'} />
          <C.DatePicker
            value={formik.values.start}
            onValueChange={(value) => formik.setFieldValue('start', value)}
          />

          <C.BlueLabel title={'步驟3. 結束時間'} />
          <C.DatePicker
            value={formik.values.end}
            onValueChange={(value) => formik.setFieldValue('end', value)}
          />
          <div className="h-3" />

          <C.Button
            title={'搜尋'}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex flex-col space-y-3">
            {data && (
              <C.Table
                columns={[
                  { title: '店號', dataIndex: 'id' },
                  { title: '店名', dataIndex: 'name' },
                  { title: '開幕日', dataIndex: 'open_dt' },
                  { title: '維修商', dataIndex: 'vendor' },
                  { title: '門市電話', dataIndex: 'phone' },
                  { title: '統一編號', dataIndex: 'tax_id' },
                  { title: '地址', dataIndex: 'addr' },
                ]}
                data={data}
                onClick={(item: any) => {
                  // alert(JSON.stringify(item));
                  setSelectedStoreId(item.id);
                }}
              />
            )}

            {data2 && (
              <C.Table
                columns={[
                  { title: '姓名', dataIndex: 'name' },
                  { title: '手機', dataIndex: 'phone' },
                  { title: '部門', dataIndex: 'department' },
                  { title: '職稱', dataIndex: 'job_title' },
                  { title: '帳號', dataIndex: 'account' },
                  { title: '密碼', dataIndex: 'pw' },
                  { title: 'email', dataIndex: 'email' },
                  { title: '啟用安保接收功能', dataIndex: 'is_amber_enable' },
                  { title: '案件指定', dataIndex: 'is_case_assign' },
                  {
                    title: '安保到期日',
                    dataIndex: 'dt_amber_due',
                    render: (item: any) => <div>{item['dt']}</div>,
                  },
                ]}
                data={data2}
              />
            )}

            {data3 && (
              <C.Table
                columns={[
                  { title: '姓名', dataIndex: 'target' },
                  { title: '手機', dataIndex: 'target' },
                  { title: '部門', dataIndex: 'target' },
                  { title: '職稱', dataIndex: 'target' },
                  { title: '帳號', dataIndex: 'target' },
                  { title: '密碼', dataIndex: 'target' },
                  { title: 'email', dataIndex: 'target' },
                  { title: '啟用7', dataIndex: 'target' },
                ]}
                data={data3}
              />
            )}
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
