import React from 'react';
import {
  Column as AntdColumn,
  Line as AntdLine,
  Pie as AntdPie,
} from '@ant-design/charts';
import moment from 'moment';

interface ChartExProps {
  chart: any;
}

const parseIfDate = (value: any) => {
  if (moment(value, moment.ISO_8601, true).isValid()) {
    return moment(value).format('YYYY-MM-DD');
  } else {
    return value;
  }
};

export const ChartEx = ({ chart }: ChartExProps) => {
  if (chart.type === 'bar') {
    const config = {
      data: chart?.data?.map((item: any) => ({
        [chart.axis[0]]: parseIfDate(item[0]),
        [chart.axis[1]]: item[1],
      })),
      xField: chart.axis[0],
      yField: chart.axis[1],
      xAxis: {
        title: {
          text: chart.axis[0],
          style: {
            fontSize: 16,
          },
        },
      },
      yAxis: {
        title: {
          text: chart.axis[1],
          style: {
            fontSize: 16,
          },
        },
      },
      color: chart.color,
      label: {
        position: 'middle',
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' },
        ],
      },
    };

    return (
      <div>
        <div className="text-center">{chart?.name}</div>

        {/* @ts-ignore */}
        <AntdColumn {...config} height={200} />
      </div>
    );
  }

  if (chart.type === 'group_bar') {
    const groups = chart.legend.value;
    const color = chart.legend.color;

    let array = [];
    for (const _d of chart.data) {
      for (let j = 0; j < _d.value.length; j++) {
        array.push({
          group: groups[j],
          [chart.axis[0]]: _d.title,
          [chart.axis[1]]: _d.value[j],
        });
      }
    }

    const config = {
      data: array,
      xField: chart.axis[0],
      yField: chart.axis[1],
      xAxis: {
        title: {
          text: chart.axis[0],
          style: {
            fontSize: 16,
          },
        },
      },
      yAxis: {
        title: {
          text: chart.axis[1],
          style: {
            fontSize: 16,
          },
        },
      },
      color: color,
      seriesField: 'group',
      isGroup: true,
      label: {
        position: 'middle',
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' },
        ],
      },
      legend: {
        itemName: {
          style: {
            fontSize: 16,
          },
        },
      },
    };

    // @ts-ignore
    return <AntdColumn {...config} height={200} />;
  }

  if (chart.type === 'pie') {
    const config = {
      appendPadding: 10,
      data: chart?.data?.map((item: any) => ({
        title: item.title,
        value: item.value,
      })),
      angleField: 'value',
      colorField: 'title',
      color: chart?.data?.map((item: any) => item.color),
      radius: 0.8,
      label: {
        type: 'outer',
        content: '{name} {percentage}',
      },
      interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
      legend: {
        itemName: {
          style: {
            fontSize: 16,
          },
        },
      },
    };

    // @ts-ignore
    return <AntdPie {...config} width={500} height={200} />;
  }

  if (chart.type === 'line') {
    const config = {
      data: chart?.data?.map((item: any) => ({
        [chart.axis[0]]: parseIfDate(item[0]),
        [chart.axis[1]]: item[1],
      })),
      xField: chart.axis[0],
      yField: chart.axis[1],
      xAxis: {
        title: {
          text: chart.axis[0],
          style: {
            fontSize: 16,
          },
        },
      },
      yAxis: {
        title: {
          text: chart.axis[1],
          style: {
            fontSize: 16,
          },
        },
      },
      color: chart.color,
    };

    // @ts-ignore
    return <AntdLine {...config} height={200} />;
  }

  return <>{chart.type}</>;
};
