import React from 'react';
import { useTranslation } from 'react-i18next';

export interface DividerProps {
  title: string;
}

export const Divider = ({ title }: DividerProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="">
      <div className="bg-gray-400" style={{ height: 1 }} />

      <div className="flex">
        <div className="bg-bar flex justify-center items-center px-5 py-2">
          <div className="text-white text-base">{title}</div>
        </div>
      </div>
    </div>
  );
};
