import React from 'react';
import { useTranslation } from 'react-i18next';

export interface HeaderUnauthedProps {
  title: string;
}

export const HeaderUnauthed = ({ title }: HeaderUnauthedProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="flex items-center space-x-4 px-5 py-3 bg-bar">
      {/* tool bar */}
      <div className="flex flex-1"></div>

      {/* title */}
      <div className="text-white font-bold text-2xl">{title}</div>

      {/* account */}
      <div className="flex flex-1 items-center justify-end"></div>
    </div>
  );
};
