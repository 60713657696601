import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';

import clsx from 'clsx';

export const Type1 = () => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [data1, setData1] = React.useState<any>(null);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', `/api/v2/util/auth/page-auth-type`)
      .then((result) => {
        if (result) {
          setData1(result);
        }
      });
  }, []);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">
        {t('編輯頁面權限類別')}
      </div>

      <div className="p-3">
        <textarea
          className="w-full border border-gray-500"
          value={data1?.auth_type
            ?.map((item: any) => {
              return item[0] + ',' + item[1].join(',');
            })
            .join('\n')}
          onChange={(evt) => setData1(evt.target.value)}
          rows={10}
        />
      </div>

      <div className="flex justify-end p-3 space-x-3">
        <C.Button
          title="確定"
          onClick={async () => {
            await appCtx.fetchApi('post', `/api/v2/util/auth/page-auth-type`);
            appCtx.setModal(null);
          }}
        />
        <C.Button title="取消" onClick={() => appCtx.setModal(null)} />
      </div>
    </div>
  );
};

export const Type2 = () => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [data1, setData1] = React.useState<any>(null);

  React.useEffect(() => {
    appCtx
      .fetchApi('get', `/api/v2/util/auth/device-auth-type`)
      .then((result) => {
        if (result) {
          setData1(result);
        }
      });
  }, []);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">
        {t('編輯設備權限類別')}
      </div>

      <div className="p-3">
        <textarea
          className="w-full border border-gray-500"
          value={data1?.auth_type
            ?.map((item: any) => {
              return item[0] + ',' + item[1].join(',');
            })
            .join('\n')}
          onChange={(evt) => setData1(evt.target.value)}
          rows={10}
        />
      </div>

      <div className="flex justify-end p-3 space-x-3">
        <C.Button
          title="確定"
          onClick={async () => {
            await appCtx.fetchApi('post', `/api/v2/util/auth/device-auth-type`);
            appCtx.setModal(null);
          }}
        />
        <C.Button title="取消" onClick={() => appCtx.setModal(null)} />
      </div>
    </div>
  );
};
