import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../AppContext';
import * as C from '../components';

export const SelectDepartmentMap = ({
  selectedDepartment,
  setSelectedDepartment,
}: {
  selectedDepartment: string;
  setSelectedDepartment: (value: string) => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [departmentData, setDepartmentData] = React.useState<any>(null);

  const [department1, setDepartment1] = React.useState<any[]>([]);
  const [department2, setDepartment2] = React.useState<any[]>([]);
  const [department3, setDepartment3] = React.useState<any[]>([]);
  const [department4, setDepartment4] = React.useState<any[]>([]);
  const [department5, setDepartment5] = React.useState<any[]>([]);
  const [department6, setDepartment6] = React.useState<any[]>([]);

  const [selected1, setSelected1] = React.useState<string>('');
  const [selected2, setSelected2] = React.useState<string>('');
  const [selected3, setSelected3] = React.useState<string>('');
  const [selected4, setSelected4] = React.useState<string>('');
  const [selected5, setSelected5] = React.useState<string>('');
  const [selected6, setSelected6] = React.useState<string>('');

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi('get', `/api/v2/department-map`).then((result) => {
        if (result) {
          setDepartmentData(result);
          setDepartment1(
            result.tree.children.map((item: any) => ({
              title: item.name,
              value: item.id,
            })),
          );
        }
      });
    }
  }, [appCtx.token]);

  // lv1
  React.useEffect(() => {
    if (selected1 === '') {
      return;
    }

    setSelected2('');
    setSelected3('');
    setSelected4('');
    setSelected5('');
    setSelected6('');

    setDepartment2(
      departmentData.tree.children
        ?.filter((item: any) => item.id == selected1)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment3([]);
    setDepartment4([]);
    setDepartment5([]);
    setDepartment6([]);

    setSelectedDepartment(selected1);
  }, [selected1]);

  // lv2
  React.useEffect(() => {
    if (selected2 === '') {
      return;
    }

    setSelected3('');
    setSelected4('');
    setSelected5('');
    setSelected6('');

    setDepartment3(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment4([]);
    setDepartment5([]);
    setDepartment6([]);

    setSelectedDepartment(selected2);
  }, [selected2]);

  // lv3
  React.useEffect(() => {
    if (selected3 === '') {
      return;
    }

    setSelected4('');
    setSelected5('');
    setSelected6('');

    setDepartment4(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.filter((item: any) => item.name == selected3)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment5([]);
    setDepartment6([]);

    setSelectedDepartment(selected3);
  }, [selected3]);

  // lv4
  React.useEffect(() => {
    if (selected4 === '') {
      return;
    }

    setSelected5('');
    setSelected6('');

    setDepartment5(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.filter((item: any) => item.name == selected3)[0]
        ?.children?.filter((item: any) => item.name == selected4)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );
    setDepartment6([]);

    setSelectedDepartment(selected4);
  }, [selected4]);

  // lv5
  React.useEffect(() => {
    if (selected5 === '') {
      return;
    }

    setSelected6('');

    setDepartment6(
      departmentData.tree.children
        ?.filter((item: any) => item.name == selected1)[0]
        ?.children?.filter((item: any) => item.name == selected2)[0]
        ?.children?.filter((item: any) => item.name == selected3)[0]
        ?.children?.filter((item: any) => item.name == selected4)[0]
        ?.children?.filter((item: any) => item.name == selected5)[0]
        ?.children?.map((item: any) => ({
          title: item.name,
          value: item.id,
        })) ?? [],
    );

    setSelectedDepartment(selected5);
  }, [selected5]);

  // lv6
  React.useEffect(() => {
    if (selected6 === '') {
      return;
    }

    setSelectedDepartment(selected6);
  }, [selected6]);

  return (
    <div className="flex flex-col space-y-3">
      <C.Select
        options={department1}
        value={selected1}
        onValueChange={setSelected1}
      />
      {department2.length > 0 && (
        <C.Select
          options={department2}
          value={selected2}
          onValueChange={setSelected2}
        />
      )}
      {department3.length > 0 && (
        <C.Select
          options={department3}
          value={selected3}
          onValueChange={setSelected3}
        />
      )}
      {department4.length > 0 && (
        <C.Select
          options={department4}
          value={selected4}
          onValueChange={setSelected4}
        />
      )}
      {department5.length > 0 && (
        <C.Select
          options={department5}
          value={selected5}
          onValueChange={setSelected5}
        />
      )}
      {department6.length > 0 && (
        <C.Select
          options={department6}
          value={selected6}
          onValueChange={setSelected6}
        />
      )}
    </div>
  );
};
