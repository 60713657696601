import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface SelectableListProps {
  className?: string;
  data?: Array<{ title: string; value: string }>;
  value: string;
  onValueChange?: (value: string) => void;
  onEdit?: (value: any) => void;
  onDelete?: (value: any) => void;
}

export const SelectableListWithAction = ({
  className,
  data,
  value,
  onValueChange,
  onEdit,
  onDelete,
}: SelectableListProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={clsx(
        'h-64 border border-gray-500 bg-white py-1 overflow-y-scroll',
        className,
      )}
    >
      {data?.map((item) => {
        return (
          <div
            className={clsx(
              'cursor-pointer px-2 py-1 flex items-center justify-between',
              value === item.value
                ? 'bg-bar text-white'
                : 'bg-white text-gray-500',
            )}
            onClick={() => {
              onValueChange && onValueChange(item.value);
            }}
          >
            <div>{item.title}</div>

            {value === item.value && (
              <div className="flex items-center space-x-3">
                <div
                  className="px-3 rounded bg-button hover:bg-button-hover cursor-pointer"
                  onClick={() => {
                    alert(JSON.stringify(item));
                    onEdit && onEdit(item);
                  }}
                >
                  {t('Edit')}
                </div>
                <div
                  className="px-3 rounded bg-button hover:bg-button-hover cursor-pointer"
                  onClick={() => {
                    alert(JSON.stringify(item));
                    onDelete && onDelete(item);
                  }}
                >
                  {t('Delete')}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
