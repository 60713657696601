import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi(
          'get',
          `/api/v2/util/lost-alarm/cfg?section=a&depart=b&store=c`,
        )
        .then((result) => {
          if (result) {
          }
        });
    },
  });

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'發送訊息'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇組織'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <div className="h-3" />

          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
        </C.SidePanel>

        <C.ContentPanel>
          <C.BlueLabel title={'步驟2. 選擇訊息類別'} />
          <div className="mb-3" />

          <div className="flex space-x-3">
            <C.Button title="新增" />
            <C.Button title="修改" />
            <C.Button title="刪除" />
          </div>
          <div className="mb-3" />

          <C.SelectableList value="" />
          <div className="mb-10" />

          <C.BlueLabel title={'步驟3. 選擇接收人員'} />
          <div className="mb-3" />

          <C.SelectableList value="" />
          <div className="mb-10" />

          <div className="flex items-center justify-between">
            <C.BlueLabel title={'步驟4. 撰寫訊息內容'} />
            <C.Button title="送出" />
          </div>
          <div className="mb-3" />

          <div className="flex flex-col space-y-5">
            <input
              className="border border-gray-500"
              placeholder="請輸入標題"
            />
            <textarea
              className="border border-gray-500"
              placeholder="請輸入內容"
            />
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
