import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any>(null);
  const [data2, setData2] = React.useState<any>(null);
  const [data3, setData3] = React.useState<any>(null);
  const [data4, setData4] = React.useState<any>(null);
  const [data5, setData5] = React.useState<any>(null);
  const [data6, setData6] = React.useState<any>(null);
  const [data7, setData7] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      start: '',
      end: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      start: Yup.string().required(t('Required')),
      end: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      // 1
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/alarm?depart=324&start=2021-
          03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      // 2
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/tb?depart=324&start=2021-
          03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      // 3
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/service?
          depart=324&start=2021-03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      // 4
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/online-fix?
          depart=324&start=2021-03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      //5
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/online-fix?
          depart=324&start=2021-03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      // 6
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/fix-maintain?
          depart=324&start=2021-03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });

      // 7
      appCtx
        .fetchApi2(
          'get',
          `/api/v2/msg/notification/records/fix-maintain?
          depart=324&start=2021-03-10T00:00:00&end=2021-03-12T23:59:59`,
        )
        .then((result) => {
          if (result) {
          }
        });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi2('get', `/api/v2/msg/notification/types`).then((result) =>
        setTypeOptions(
          result.types.map((item: any) => ({
            title: item.title,
            value: item.type_code,
          })),
        ),
      );
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'系統訊息通知紀錄'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 選擇組織'} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />

          <C.DatePicker
            value={formik.values.start}
            onValueChange={(value) => formik.setFieldValue('start', value)}
          />
          <C.DatePicker
            value={formik.values.end}
            onValueChange={(value) => formik.setFieldValue('end', value)}
          />
          <div className="h-3" />

          <C.Button
            title={'搜尋'}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />

          <div className="h-3" />

          <C.BlueLabel title={'步驟2. 選擇通知類型'} />

          <C.Select options={typeOptions} value="" />
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex justify-end mb-5">
            <C.Button title={'儲存'} />
          </div>

          {data && (
            <C.Table2
              label={data?.table?.title}
              header={data?.table?.header}
              data={data?.table?.data}
              page_size={data?.table?.page_size}
              filter_index={data?.table?.filter_index}
            />
          )}

          {data2 && (
            <C.Table2
              label={data2?.table?.title}
              header={data2?.table?.header}
              data={data2?.table?.data}
              page_size={data2?.table?.page_size}
              filter_index={data2?.table?.filter_index}
            />
          )}

          {data3 && (
            <C.Table2
              label={data3?.table?.title}
              header={data3?.table?.header}
              data={data3?.table?.data}
              page_size={data3?.table?.page_size}
              filter_index={data3?.table?.filter_index}
            />
          )}

          {data4 && (
            <C.Table2
              label={data4?.table?.title}
              header={data4?.table?.header}
              data={data4?.table?.data}
              page_size={data4?.table?.page_size}
              filter_index={data4?.table?.filter_index}
            />
          )}

          {data5 && (
            <C.Table2
              label={data5?.table?.title}
              header={data5?.table?.header}
              data={data5?.table?.data}
              page_size={data5?.table?.page_size}
              filter_index={data5?.table?.filter_index}
            />
          )}

          {data6 && (
            <C.Table2
              label={data6?.table?.title}
              header={data6?.table?.header}
              data={data6?.table?.data}
              page_size={data6?.table?.page_size}
              filter_index={data6?.table?.filter_index}
            />
          )}

          {data7 && (
            <C.Table2
              label={data7?.table?.title}
              header={data7?.table?.header}
              data={data7?.table?.data}
              page_size={data7?.table?.page_size}
              filter_index={data7?.table?.filter_index}
            />
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
