import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Button } from './Button';
import { TextInput } from './TextInput';

export interface SelectableListProps {
  className?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  data?: Array<string>;
  onEdit?: (value: Array<string>) => void;
}

export const SelectableListWithAdd = ({
  className,
  data,
  onEdit,
  value,
  onValueChange,
}: SelectableListProps) => {
  const { t, i18n } = useTranslation();
  const [newText, setNewText] = useState('');

  return (
    <div>
      <div
        className={clsx(
          'h-64 border border-gray-500 bg-white py-1 overflow-y-scroll',
          className,
        )}
      >
        {data?.map((item, index) => {
          return (
            <div
              className={clsx(
                'px-2 py-1 flex items-center justify-between',
                value === item ? 'bg-bar text-white' : 'bg-white text-gray-500',
              )}
              // onClick={() => {
              //   onValueChange && onValueChange(item.value);
              // }}
            >
              <div>{item}</div>
              <div
                className="cursor-pointer text-red-500"
                onClick={() => {
                  onEdit &&
                    data &&
                    onEdit(data.filter((_, _index) => index !== _index));
                }}
              >
                刪除
              </div>
            </div>
          );
        })}
      </div>

      <div className="flex items-center ml-10 mt-3 space-x-3">
        <TextInput value={newText} onValueChange={setNewText} />
        <Button
          title="新增"
          onClick={() => {
            if (newText) {
              onEdit && data && onEdit([...data, newText]);
              setNewText('');
            }
          }}
        />
      </div>
    </div>
  );
};
