import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';

import clsx from 'clsx';

export const DeleteMsgBox = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div
        className="text-white text-center py-1"
        style={{ backgroundColor: '#CF7796' }}
      >
        {t('Delete')}
      </div>

      <div className="p-3">
        <div>{'您確定要刪除嗎？'}</div>

        <div className="flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={onOK}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
