import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';

import * as CC from './_m_32_';
import * as CC2 from './_m_32_2';
import * as CCS from './_m_32_save_';
import * as CD from './_m_delete';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [note, setNote] = React.useState<string>('');

  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);
  const [option3, setOption3] = React.useState<any[]>([]);
  const [option4, setOption4] = React.useState<any[]>([]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      // department: '',
      // store: '',
      // storeQuick: '',
      brand: '',
      type: '',
      location: '',
      paramType: '',
    },
    validationSchema: Yup.object().shape({
      // department: Yup.string().required(t('Required')),
      // store: Yup.string().required(t('Required')),
      // storeQuick: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi(
          'get',
          `/api/v2/util/lost-alarm/cfg?section=a&depart=b&store=c`,
        )
        .then((result) => {
          if (result) {
          }
        });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi2('get', `/api/v2/fridge/brand`).then((result) => {
        setOption1(
          result.brands.map((item: any) => ({
            title: item.name,
            value: item.id,
          })),
        );
      });

      appCtx.fetchApi2('get', `/api/v2/fridge/device-type`).then((result) => {
        setOption2(
          result.fridges.map((item: any) => ({
            title: item.title,
            value: item.id,
          })),
        );
      });

      appCtx.fetchApi2('get', `/api/v2/fridge/location`).then((result) => {
        setOption3(
          result.locations.map((item: any) => ({
            title: item.title,
            value: item.id,
          })),
        );
      });

      appCtx.fetchApi2('get', `/api/v2/tc/param-type`).then((result) => {
        setOption4(
          result.types.map((item: any) => ({
            title: item.title,
            value: item.id,
          })),
        );
      });

      appCtx
        .fetchApi2('get', `/api/v2/tc/param/service/note`)
        .then((result) => {
          setNote(result.note);
        });
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'溫控參數管理'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={'步驟1. 指定冰櫃'} />
          <C.Select
            options={option1}
            placeholder="廠牌"
            value={formik.values.brand}
            onValueChange={(value) => formik.setFieldValue('brand', value)}
          />
          <C.Select
            options={option2}
            placeholder="冰櫃類型"
            value={formik.values.type}
            onValueChange={(value) => formik.setFieldValue('type', value)}
          />
          <C.Select
            options={option3}
            placeholder="使用場所"
            value={formik.values.location}
            onValueChange={(value) => formik.setFieldValue('location', value)}
          />
          <div className="h-3" />

          <C.Button
            title="搜尋"
            onClick={() => {
              appCtx
                .fetchApi2(
                  'get',
                  `/api/v2/tc/param-profile?type-code=1&brand=${formik.values.brand}&dev-type=${formik.values.type}&location=${formik.values.location}`,
                )
                .then((result) => {});
            }}
          />
          <div>說明：選項內若無對應冰櫃資訊，請再下方功能鍵新增</div>

          <div className="h-1 border-b border-gray-300 my-10" />

          <C.Button
            title="新增冰櫃廠牌"
            onClick={() => {
              appCtx.setModal(
                <CC.NewFridge
                  onOK={() => {
                    // refresh();
                    appCtx.setModal(null);
                  }}
                  onCancel={() => appCtx.setModal(null)}
                />,
              );
            }}
          />
          <C.Button
            title="管理使用場所"
            onClick={() => {
              appCtx.setModal(
                <CC.ManagerLocation
                  onOK={() => {
                    // refresh();
                    appCtx.setModal(null);
                  }}
                  onCancel={() => appCtx.setModal(null)}
                />,
                1500,
              );
            }}
          />
          <C.Button
            title="管理冰櫃類型"
            onClick={() => {
              appCtx.setModal(
                <CC2.ManagerType
                  onOK={() => {
                    // refresh();
                    appCtx.setModal(null);
                  }}
                  onCancel={() => appCtx.setModal(null)}
                />,
                1500,
              );
            }}
          />
          <div>說明：送出後，池御會儘速幫您審核， 並將冰櫃廠牌加入清單</div>
        </C.SidePanel>

        <C.ContentPanel>
          <C.BlueLabel title={'步驟2. 選擇欲編輯/查看的參數檔'} />
          <div className="h-3" />

          <C.Select
            className="w-full"
            options={option4}
            placeholder="廠牌"
            value={formik.values.paramType}
            onValueChange={(value) => formik.setFieldValue('paramType', value)}
          />
          <div className="h-3" />

          <div className="flex space-x-3">
            <C.Button
              className="flex-1"
              title="新增"
              onClick={() => {
                prompt('新增');
              }}
            />
            <C.Button className="flex-1" title="複製" onClick={() => {}} />
            <C.Button className="flex-1" title="刪除" onClick={() => {}} />
          </div>
          <div className="h-3" />

          <C.SelectableList value="" />
          <div className="h-10" />

          <C.BlueLabel title={'步驟3. 參數編輯/更新'} />
          <div className="h-3" />

          <div className="flex items-center space-x-3">
            <div>目前正在編輯中檔案名稱</div>
            <input className="border border-gray-500" />
            <C.Button title="儲存" onClick={() => {}} />
          </div>

          <div className="text-red-400">{note}</div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
