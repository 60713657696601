import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
      storeQuick: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
      storeQuick: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi(
          'get',
          `/api/v2/util/lost-alarm/cfg?section=a&depart=b&store=c`,
        )
        .then((result) => {
          if (result) {
          }
        });
    },
  });

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'門店設定'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.ContentPanel>
          <div className="grid grid-cols-4 gap-5">
            {/* 1 */}
            <div>
              <C.BlueLabel title={'步驟1. 選擇組織'} />
              <div className="h-3" />

              <SelectDepartmentMap
                selectedDepartment={formik.values.department}
                setSelectedDepartment={(value) =>
                  formik.setFieldValue('department', value)
                }
              />
              <div className="h-3" />

              <div className="flex space-x-3">
                <C.Button
                  title={t('組織管理')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
                <C.Button
                  title={t('搜尋')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
              </div>
              <div className="h-3" />
            </div>

            {/* 2 */}
            <div>
              <C.BlueLabel title={'步驟2. 點選門市'} />
              <div className="h-3" />

              <div className="flex space-x-3">
                <C.Button
                  title={t('新增')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
                <C.Button
                  title={t('複製')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
                <C.Button
                  title={t('刪除')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
              </div>
              <div className="h-3" />

              <div className="text-gray-500">可於門市清單最後列新增門市：</div>
              <C.SelectableList value="" />
              <div className="h-3" />

              <div className="text-gray-500">忘記門市可直接輸入店號查詢：</div>
              <div className="w-full flex flex-col items-stretch">
                <SelectStoreQuick
                  selectedStore=""
                  setSelectedStore={() => {}}
                />
                <div className="h-1" />
                <C.Button
                  title={t('選擇')}
                  onClick={() => formik.submitForm()}
                  disabled={!formik.isValid}
                />
              </div>
            </div>

            {/* 3 */}
            <div>
              <C.BlueLabel title={'步驟3. 門市資訊 (store.txt)'} />
              <div className="h-3" />

              <div className="grid grid-cols-2 gap-3">
                <div>-維修商</div>
                <input className="border border-gray-500" />
                <div>-店名</div>
                <input className="border border-gray-500" />
                <div>-店號</div>
                <input className="border border-gray-500" />
                <div>-統一編號</div>
                <input className="border border-gray-500" />
                <div>-門市電話</div>
                <input className="border border-gray-500" />
                <div>-地址</div>
                <input className="border border-gray-500" />
                <div>-開幕日</div>
                <input className="border border-gray-500" />
                <div>-店長</div>
                <input className="border border-gray-500" />
                <div>-備註</div>
                <input className="border border-gray-500" />
                <div>-終止服務時間</div>
                <input className="border border-gray-500" />
              </div>
            </div>

            {/* 4 */}
            <div>
              <C.BlueLabel title={'步驟4. 聯網與異常通知'} />
              <div className="h-3" />

              <div className="flex items-center space-x-3">
                <div className="">傳訊博士狀態</div>
                <input className="border border-gray-500" />
              </div>
              <div className="h-3" />

              <div className="flex items-center space-x-3">
                <input className="border border-gray-500" type="checkbox" />
                <div className="">啟用amber</div>
              </div>
              <div className="h-3" />

              <div className="flex items-center space-x-3">
                <input
                  className="border border-gray-500 opacity-0"
                  type="checkbox"
                />
                <div className="">工程師</div>
              </div>
              <div className="h-3" />

              <div>
                - Amber 通知對象: btn，轉跳到3-4 - 維修進度通報機制: 如下
              </div>
              <div className="h-3" />

              <div className="grid grid-cols-3 gap-3">
                <div>
                  <div>進度</div>
                  <C.SelectableList value="" />
                </div>

                <div>
                  <div>進度期限</div>
                  <C.SelectableList value="" />
                </div>

                <div>
                  <div>逾時通報</div>
                  <C.SelectableList value="" />
                </div>
              </div>
            </div>
          </div>

          <div className="my-10">
            <C.BlueLabel title={'步驟5. 建立冰櫃清單'} />
            <div className="h-3" />

            <div className="">- 冰櫃廠牌/型號:label</div>
            <div className="">- 場域/參數名稱:label</div>
            <div className="">- 分時節能溫控:label</div>
          </div>

          <div className="grid grid-cols-2 gap-5">
            {/* 6 */}
            <div>
              <C.BlueLabel title={'步驟6. 傳訊博士設定'} />
              <div className="h-3" />

              <div className="grid grid-cols-2 gap-3">
                <div>
                  <div className="font-bold">發報設定 (Alarm.txt)</div>
                  <div className="grid grid-cols-2 gap-3">
                    <div>告警發報延遲</div>
                    <div>告警發報延遲</div>
                    <div>重複發報延遲</div>
                    <div>重複發報延遲</div>
                  </div>
                </div>

                <div>
                  <div className="font-bold">TB回報(machine)</div>

                  <div className="font-bold">AP連線參數 (AP.txt)</div>
                </div>
              </div>
            </div>

            {/* 7 */}
            <div>
              <C.BlueLabel title={'步驟7. 加值服務'} />
              <div className="h-3" />
            </div>
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
