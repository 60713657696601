import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CompanyLabel } from './CompanyLabel';

export interface BreadcrumbsProps {
  path: string[];
  current: string;
}

export const Breadcrumbs = ({ path, current }: BreadcrumbsProps) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  return (
    <div className="flex items-center px-5 py-2 space-x-2 fixed top-14 left-0 right-0 bg-white z-40">
      <div className="text-base">#</div>

      {path.map((item) => (
        <>
          <div
            className="mx-2 text-base cursor-pointer"
            onClick={() => {
              if (item === t('Home')) {
                history.push('/home');
              }
            }}
          >
            {item}
          </div>
          <div className="mx-2 text-base">/</div>
        </>
      ))}

      <div className="text-gray-500 text-base">{current}</div>

      <div className="flex-1" />

      {/* <CompanyLabel /> */}
    </div>
  );
};
