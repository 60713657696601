import React from 'react';

export const ComponentSlider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="flex justify-center items-center space-x-5">
      <div
        className="cursor-pointer"
        onClick={() => {
          ref.current?.scrollBy({ left: -200, behavior: 'smooth' });
        }}
      >
        <i
          className="fa fa-play transform rotate-180"
          style={{ color: 'gray', fontSize: 30 }}
        />
      </div>

      <div
        ref={ref}
        className="flex-1 flex overflow-hidden items-center space-x-3"
      >
        {children}
      </div>

      <div
        className="cursor-pointer"
        onClick={() => {
          ref.current?.scrollBy({ left: 200, behavior: 'smooth' });
        }}
      >
        <i className="fa fa-play" style={{ color: 'gray', fontSize: 30 }} />
      </div>
    </div>
  );
};
