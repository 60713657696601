import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as antd from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import * as M from './_m_31_ ';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [data, setData] = React.useState<any[]>([]);
  const [data2, setData2] = React.useState<any>(null);

  const [service1, setService1] = React.useState<any[]>([]);
  const [service2, setService2] = React.useState<any[]>([]);
  const [service3, setService3] = React.useState<any[]>([]);
  const [service4, setService4] = React.useState<any[]>([]);
  const [service5, setService5] = React.useState<any[]>([]);
  const [service6, setService6] = React.useState<any[]>([]);
  const [service7, setService7] = React.useState<any[]>([]);
  const [service8, setService8] = React.useState<any[]>([]);
  const [service9, setService9] = React.useState<any[]>([]);
  const [service10, setService10] = React.useState<any[]>([]);
  const [service11, setService11] = React.useState<any[]>([]);
  const [service12, setService12] = React.useState<any[]>([]);

  const refresh = () => {
    appCtx.fetchApi('get', '/api/v2/service/point').then((result) => {
      if (result) {
        // setData(result);
        let array: any[] = [];
        result.services.map((item: any) => {
          item.subservices.map((item2: any) => {
            array.push({
              service_type: item.service_type,
              name: item.name,
              unit: item.unit,
              title: item2.title,
              point0: item2.point[0],
              point1: item2.point[1],
              point2: item2.point[2],
              point3: item2.point[3],
            });
          });
        });
        setData(array);
      }
    });
    appCtx.fetchApi('get', '/api/v2/service/point/detail').then((result) => {
      if (result) {
        // setData2(result);
        setService1(
          result.services.filter((item: any) => item.service_id === 1),
        );
        setService2(
          result.services.filter((item: any) => item.service_id === 2),
        );
        setService3(
          result.services.filter((item: any) => item.service_id === 3),
        );
        setService4(
          result.services.filter((item: any) => item.service_id === 4),
        );
        setService5(
          result.services.filter((item: any) => item.service_id === 5),
        );
        setService6(
          result.services.filter((item: any) => item.service_id === 6),
        );
        setService7(
          result.services.filter((item: any) => item.service_id === 7),
        );
        setService8(
          result.services.filter((item: any) => item.service_id === 8),
        );
        setService9(
          result.services.filter((item: any) => item.service_id === 9),
        );
        setService10(
          result.services.filter((item: any) => item.service_id === 10),
        );
        setService11(
          result.services.filter((item: any) => item.service_id === 11),
        );
        setService12(
          result.services.filter((item: any) => item.service_id === 12),
        );
      }
    });
  };

  React.useEffect(() => {
    if (appCtx.token) {
      refresh();
    }
  }, [appCtx.token]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={'門店設定'} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.ContentPanel>
          <C.BlueLabel title={'加值服務點數清單'} />
          <div className="flex justify-end">
            <C.Button
              title="申請"
              onClick={() =>
                appCtx.setModal(
                  <M.M1
                    onOK={() => {
                      refresh();
                      appCtx.setModal(null);
                    }}
                    onCancel={() => appCtx.setModal(null)}
                  />,
                )
              }
            />
          </div>
          {data.length > 0 && (
            <C.Table
              columns={[
                { title: '服務', dataIndex: 'name' },
                { title: '單位', dataIndex: 'unit' },
                { title: '', dataIndex: 'title' },
                { title: '總數', dataIndex: 'point0' },
                { title: '已使用', dataIndex: 'point1' },
                { title: '未使用', dataIndex: 'point2' },
                { title: '將使用', dataIndex: 'point3' },
              ]}
              data={data}
            />
          )}
          <div className="h-3" />

          <C.BlueLabel title={'詳細資訊'} />
          <div className="flex flex-col space-y-3">
            {service1.length > 0 && (
              <C.Table
                columns={[
                  { title: '時間', dataIndex: 'time' },
                  { title: '店名', dataIndex: 'store_name' },
                  { title: 'TB(sn)', dataIndex: 'tb_sn' },
                  { title: '設備(sn)', dataIndex: 'device_sn' },
                  { title: '扣點結果', dataIndex: 'result' },
                ]}
                data={service1}
              />
            )}
            {service2.length > 0 && <div>{JSON.stringify(service2)}</div>}
            {service3.length > 0 && (
              <C.Table
                columns={[
                  { title: '時間', dataIndex: 'time' },
                  { title: '店名', dataIndex: 'store_name' },
                  { title: '維修開始', dataIndex: 'fix_start' },
                  { title: '維修結束', dataIndex: 'fix_end' },
                ]}
                data={service3}
              />
            )}
            {service4.length > 0 && <div>{JSON.stringify(service4)}</div>}
            {service5.length > 0 && <div>{JSON.stringify(service5)}</div>}
            {service6.length > 0 && <div>{JSON.stringify(service6)}</div>}
            {service7.length > 0 && <div>{JSON.stringify(service7)}</div>}
            {service8.length > 0 && <div>{JSON.stringify(service8)}</div>}
            {service9.length > 0 && <div>{JSON.stringify(service9)}</div>}
            {service10.length > 0 && <div>{JSON.stringify(service10)}</div>}
            {service11.length > 0 && <div>{JSON.stringify(service11)}</div>}
            {service12.length > 0 && <div>{JSON.stringify(service12)}</div>}
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
