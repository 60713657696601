import React from 'react';
import clsx from 'clsx';

import { Button } from './Button';

export interface FilePickerProps {
  className?: string;
  title: string;
  onClick?: (fileList: FileList | null) => void;
  disabled?: boolean;
}

export const FilePicker = ({
  className,
  title,
  onClick,
  disabled,
}: FilePickerProps) => {
  const selectFile = () => {
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = (_) => {
      onClick && onClick(input.files);
    };
    input.click();
  };

  return (
    <Button
      className={className}
      title={title}
      onClick={selectFile}
      disabled={disabled}
    />
  );
};
