// TODO : get /api/v2/util/department/sample not found
// TODO : patch /api/v2/util/department/update not found

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [filePath, setFilePath] = React.useState<string>('');

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Organization')} />

      <div className="flex-1 flex flex-col md:flex-row">
        {/* <C.SidePanel /> */}
        <C.ContentPanel>
          <C.BlueLabel
            className="mb-3"
            title={`1.${t('Select update file')}`}
            noDash
          />

          <div className="flex space-x-3 mb-3">
            <C.FilePicker
              title={t('Select file')}
              onClick={(fileList) => {
                if (fileList && fileList.length > 0) {
                  setFilePath(fileList[0].name);
                }
              }}
            />

            <C.Button
              title={t('Example')}
              onClick={() => {
                appCtx
                  .fetchApi('get', `/api/v2/util/department/sample`)
                  .then((result) => {
                    if (result) {
                    }
                  });
              }}
            />
          </div>

          <div className="flex space-x-3 mb-3">
            <div>{filePath ? filePath : t('Selected file path')}</div>
          </div>

          <div className="h-3" />
          <div className="h-3" />

          <C.BlueLabel
            className="mb-3"
            title={`2.${t('Update organization architecture')}`}
            noDash
          />

          <div className="flex flex-col space-y-3 w-72">
            <C.SelectableList
              data={[
                { title: '123456 測試2號店 OK', value: '123456 測試2號店 OK' },
              ]}
              value="123456 測試2號店 OK"
            />

            <C.Button
              title={t('Renew')}
              onClick={() => {
                appCtx
                  .fetchApi('patch', `/api/v2/util/department/update`)
                  .then((result) => {
                    if (result) {
                    }
                  });
              }}
            />
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
