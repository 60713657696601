import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface BlueLabelProps {
  className?: string;
  title: string;
  noDash?: boolean;
}

export const BlueLabel = ({ className, title, noDash }: BlueLabelProps) => {
  const { t, i18n } = useTranslation();

  if (noDash) {
    return (
      <div className={clsx('text-bar text-xl font-bold', className)}>
        {title}
      </div>
    );
  }

  return (
    <div className={clsx('text-bar text-xl font-bold', className)}>
      -{title}
    </div>
  );
};
