import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface TextInputProps {
  className?: string;
  value: string;
  onValueChange?: (value: string) => void;
  placeholder?: string;
  type?: string;
}

export const TextInput = ({
  className,
  value,
  onValueChange,
  placeholder,
  type = 'text',
}: TextInputProps) => {
  const { t, i18n } = useTranslation();

  return (
    <input
      className={clsx(
        'h-10 border border-gray-500 px-2 text-gray-500',
        className,
      )}
      value={value}
      onChange={(evt) => onValueChange && onValueChange(evt.target.value)}
      placeholder={placeholder}
      type={type}
    />
  );
};
