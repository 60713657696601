// done

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeQuick, setStoreQuick] = React.useState<string>('');
  const [dataType, setDataType] = React.useState<any>(null);
  const [step1, setStep1] = React.useState<any[]>([]);
  const [step2, setStep2] = React.useState<any[]>([]);

  const [selectedDataType, setSelectedDataType] = React.useState<any>('');
  const [selectedStep1, setSelectedStep1] = React.useState<any>('');
  const [selectedStep2, setSelectedStep2] = React.useState<any>('');

  const [list, setList] = React.useState<any[]>([]);
  const [selectedList, setSelectedList] = React.useState<any>('');

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
      dateStrat: '',
      dateEnd: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      // appCtx
      //   .fetchApi('get', `/api/v2/data-dl/`)
      //   .then((result) => {
      //     if (result) {
      //       // setData(result);
      //     }
      //   });
    },
  });

  React.useEffect(() => {
    if (appCtx.token) {
      appCtx.fetchApi('get', `/api/v2/data-dl/data-type`).then((result) => {
        if (result) {
          setDataType(result.types);
        }
      });

      appCtx.fetchApi('get', `/api/v2/data-dl/status`).then((result) => {
        if (result) {
          // setDataType(result.types);
        }
      });
    }
  }, [appCtx.token]);

  React.useEffect(() => {
    if (!selectedDataType) {
      return;
    }

    appCtx
      .fetchApi(
        'get',
        `/api/v2/data-dl/device-types?data-type=${selectedDataType}`,
      )
      .then((result) => {
        if (result) {
          setStep1(
            result.types.map((item: any) => ({
              title: item.title,
              value: item.id,
            })),
          );
        }
      });
  }, [selectedDataType]);

  React.useEffect(() => {
    if (!selectedDataType || !selectedStep1) {
      return;
    }

    setSelectedStep2('');

    appCtx
      .fetchApi(
        'get',
        `/api/v2/data-dl/sensors?data-type=${selectedDataType}&dev=${selectedStep1}`,
      )
      .then((result) => {
        if (result) {
          setStep2(
            result.sensors.map((item: any) => ({
              title: item.title,
              value: item.id,
            })),
          );
        }
      });
  }, [selectedDataType, selectedStep1]);

  React.useEffect(() => {
    if (!selectedStep2) {
      return;
    }

    setList((prev) => {
      const a = `${selectedStep1},${selectedStep2}`;
      return [...prev, { title: a, value: a }];
    });
  }, [selectedStep2]);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Sensor information')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={t('Select')} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          <C.BlueLabel title={`1.${t('店舖')}`} noDash />
          <SelectStoreQuick
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />

          <C.BlueLabel title={`2.${t('Start date')}`} noDash />
          <C.DatePicker
            value={formik.values.dateStrat}
            onValueChange={(value) => formik.setFieldValue('dateStrat', value)}
          />

          <C.BlueLabel title={`3.${t('End date')}`} noDash />
          <C.DatePicker
            value={formik.values.dateEnd}
            onValueChange={(value) => formik.setFieldValue('dateEnd', value)}
          />

          <C.Select
            options={dataType?.map((item: any) => ({
              value: item.uid,
              title: item.title,
            }))}
            value={selectedDataType}
            onValueChange={(value) => setSelectedDataType(value)}
          />
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex space-y-3 lg:space-y-0 lg:space-x-3 flex-col lg:flex-row">
            <div className="flex-1 flex flex-col space-y-3">
              <C.BlueLabel title="步驟1: 選設備" noDash />

              <C.SelectableList
                // className="ml-10 w-72"
                data={step1}
                value={selectedStep1}
                onValueChange={(value) => setSelectedStep1(value)}
              />
            </div>

            <div className="flex-1 flex flex-col space-y-3">
              <C.BlueLabel title="步驟2: 選感測值" noDash />

              <C.SelectableList
                // className="ml-10 w-72"
                data={step2}
                value={selectedStep2}
                onValueChange={(value) => setSelectedStep2(value)}
              />
            </div>

            <div className="flex-1 flex flex-col space-y-3">
              <div className="flex items-center space-x-3">
                <C.BlueLabel title={t('Preparing for download list')} />
                <div
                  className="bg-button px-3 text-white text-sm cursor-pointer rounded"
                  onClick={() => {
                    setList((prev) =>
                      prev.filter((item) => item.value !== selectedList),
                    );
                  }}
                >
                  {t('Delete')}
                </div>
              </div>

              <C.SelectableList
                // className="ml-10 w-72"
                data={list}
                value={selectedList}
                onValueChange={(value) => setSelectedList(value)}
              />
            </div>

            <div className="flex-1 flex flex-col space-y-3">
              <div className="opacity-0">
                <C.BlueLabel title="步驟2: 選感測值" noDash />
              </div>

              <C.Button
                disabled={list.length === 0}
                title={t('Report')}
                onClick={() => {
                  appCtx.setModal(
                    <GenReport
                      store={formik.values.store}
                      dateStart={formik.values.dateStrat}
                      dateEnd={formik.values.dateEnd}
                      list={list.map((item) => item.value)}
                      dataType={selectedDataType}
                      onOK={() => appCtx.setModal(null)}
                      onCancel={() => appCtx.setModal(null)}
                    />,
                  );
                }}
              />
              <C.Button
                title={t('View record')}
                onClick={() => {
                  appCtx.setModal(
                    <DownloadReport
                      onOK={() => appCtx.setModal(null)}
                      onCancel={() => appCtx.setModal(null)}
                    />,
                    1200,
                  );
                }}
              />

              {/* <div className="text-button">
                *{t('Please confirm the execution result by download record')}
              </div>
              <div className="text-button">*{t('Download preparation')}</div> */}
            </div>
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};

const GenReport = ({
  store,
  dateStart,
  dateEnd,
  list,
  dataType,
  onOK,
  onCancel,
}: {
  store: string;
  dateStart: string;
  dateEnd: string;
  list: string[];
  dataType: string;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{`開始產生報表`}</div>

      <div className="p-3 space-y-3">
        <div>{`日期：${dateStart}~${dateEnd}`}</div>
        <div>項目：</div>
        {list.map((item, index) => (
          <div>{`${index}. ${item}`}</div>
        ))}

        <div className="flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={() => {
              appCtx
                .fetchApi('post', '/api/v2/data-dl/gen-report', {
                  store_id: store,
                  dt_start: moment(dateStart)
                    .startOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                  dt_end: moment(dateEnd)
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss'),
                  data_type: dataType,
                  timezone: '+08:00',
                  query: [list],
                })
                .then((result) => {
                  if (result) {
                    onOK();
                  }
                });
            }}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

const DownloadReport = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);

  const [data, setData] = React.useState<any>(null);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/data-dl/records', {}).then((result) => {
      if (result) {
        setData(result);
      }
    });
  }, []);

  const cancelTask = () => {
    appCtx
      .fetchApi('get', '/api/v2/data-dl/cancel?uid=xxx', {})
      .then((result) => {
        if (result) {
          // onOK();
        }
      });
  };

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar flex justify-between">
        <div></div>

        <div>{`下載紀錄`}</div>

        <div
          className="w-5 h-5 bg-red-400 flex items-center justify-center cursor-pointer"
          onClick={() => onCancel()}
        >
          x
        </div>
      </div>

      <div className="p-3 space-y-3">
        {data && (
          <C.Table2
            label={data?.table?.title}
            header={data?.table?.header}
            data={data?.table?.data}
            page_size={data?.table?.page_size}
            filter_index={data?.table?.filter_index}
          />
        )}

        <div className="flex justify-end space-x-2"></div>
      </div>
    </div>
  );
};
