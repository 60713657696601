import React from 'react';
import { Column as AntdColumn } from '@ant-design/charts';

export interface ColumnProps {
  width?: number;
  data: any[];
  seriesField: string;
  xField: string;
  yField: string;
}

export const Column = ({
  width,
  data,
  seriesField,
  xField,
  yField,
}: ColumnProps) => {
  var config = {
    data: data,
    isGroup: true,
    seriesField,
    xField,
    yField,
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' },
      ],
    },
    // colorField: 'type', // or seriesField in some cases
    color: ['#8DC97D', '#A977CF', '#7BC7CB'],
  };

  // @ts-ignore
  return <AntdColumn {...config} height={200} width={width} />;
};
