import React from 'react';
import clsx from 'clsx';

export interface CheckboxProps {
  className?: string;
  value?: boolean;
  onValueChange?: (value: boolean) => void;
  label?: string;
  placeholder?: string;
}

export const Checkbox = ({
  className,
  value,
  onValueChange,
  label,
  placeholder,
}: CheckboxProps) => {
  return (
    <div className={className}>
      <label className="text-gray-500">{label}</label>

      <div
        className="w-full h-10 mt-2 px-3 flex items-center border border-gray-300 rounded-lg cursor-pointer"
        onClick={() => onValueChange && onValueChange(!value)}
      >
        <input
          className={'bg-gray-200 text-gray-500'}
          type="checkbox"
          checked={value}
          onChange={(evt) => onValueChange && onValueChange(evt.target.checked)}
        />
        <div className="text-gray-500 ml-3">{placeholder}</div>
      </div>
    </div>
  );
};
