import React from 'react';
import { useHistory } from 'react-router-dom';

import * as antd from 'antd';

import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeId, setStoreId] = React.useState<string>('');
  const [uid, setUid] = React.useState<string | null>(null); // also connecting or not
  const [refreshDuration, setRefreshDuration] = React.useState<number>(0);
  const [imgUrl, setImgUrl] = React.useState<string>('');
  const [imgWidth, setImgWidth] = React.useState<number>(0);
  const [imgHeight, setImgHeight] = React.useState<number>(0);

  const [status, setStatus] = React.useState<string>('idle');

  const [polling, setPolling] = React.useState<string>('');

  const [text, setText] = React.useState<string>('');

  const doPolling = () => {
    appCtx.fetchApi('get', `/api/v2/remote/status?uid=${uid}`).then((res) => {
      console.log(res.data);
      // setPolling(JSON.stringify(res.data));
    });
  };

  React.useEffect(() => {
    let timerId: any;

    if (uid) {
      doPolling();
      timerId = setInterval(() => {
        doPolling();
      }, refreshDuration * 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [uid, refreshDuration]);

  React.useEffect(() => {
    // return () => {
    //   if (uid) {
    //     appCtx
    //       .fetchApi('get', `/api/v2/remote/disconnect?uid=${uid}`)
    //       .then((res) => {
    //         setUid(null);
    //       });
    //   }
    // };
  }, [uid]);

  React.useEffect(() => {
    if (imgUrl !== '') {
      const img = document.getElementById('imageid');

      const width = img?.clientWidth || 10;
      const height = img?.clientHeight || 10;

      setImgWidth(width);
      setImgHeight(height);
    }
  }, [imgUrl]);

  React.useEffect(() => {
    if (polling === '') {
      return;
    }

    const _polling = JSON.parse(polling);
    if (_polling.status === 'connecting') {
      setStatus('connecting');
    } else {
      setStatus('idle');
    }
  }, [polling]);

  const onConnect = () => {
    appCtx
      .fetchApi('get', `/api/v2/remote/connect?storeid=${storeId}`)
      .then((res) => {
        if (res.status_code === 102) {
          alert(res.status);
        } else {
          setUid(res.uid);
          setRefreshDuration(res.refresh_duration);
          setImgUrl(res.img_url);
        }
      });
  };

  const onDisconnect = () => {
    appCtx.fetchApi('get', `/api/v2/remote/end?uid=${uid}`);
  };

  const onKeyInput = () => {
    if (uid === null) {
      return;
    }

    appCtx
      .fetchApi('post', `/api/v2/remote/key-input`, { uid, keyin: text })
      .then((res) => {
        // alert(JSON.stringify(res.data));
      });
  };

  const onMouseInput = (x: number, y: number, click: 1 | 2, key: 0 | 1) => {
    if (uid === null) {
      return;
    }

    appCtx
      .fetchApi('post', `/api/v2/remote/mouse`, {
        uid,
        x: (x * imgWidth) / (96 * 4),
        y: (x * imgHeight) / (64 * 4),
        click,
        key,
      })
      .then((res) => {
        // alert(JSON.stringify(res.data));
      });
  };

  const onScreenCapture = () => {
    window.open(imgUrl, '_blank');
  };

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current="線上檢修-TGT" />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title="店名/店號" />
          {/* <C.Select
            options={[{ title: '18228', value: '18228' }]}
            value={storeId}
            onValueChange={setStoreId}
          /> */}
          <SelectStoreQuick
            selectedStore={storeId}
            setSelectedStore={setStoreId}
          />
          <div className="h-3" />

          <C.Button title="連線" onClick={onConnect} disabled={!storeId} />
          <C.Button title="斷線" onClick={onDisconnect} disabled={!uid} />
        </C.SidePanel>

        <C.ContentPanel fixedHeight>
          <C.BlueLabel className="mb-3" title="遙控" />

          <C.Button className="mb-3" title="截圖" onClick={onScreenCapture} />

          <div className="flex items-center">
            <C.TextInput
              value={text}
              onValueChange={setText}
              placeholder="請輸入文字"
            />
            <C.Button className="ml-3" title="連線" onClick={onKeyInput} />
          </div>

          <div className="flex">
            <div className="bg-trueGray-800 mt-5 p-7 rounded-xl flex justify-start">
              <div
                onClick={(evt) => {
                  if (evt.buttons === 0) {
                    onMouseInput(evt.clientX, evt.clientY, 1, 0);
                  }
                  if (evt.buttons === 1) {
                    onMouseInput(evt.clientX, evt.clientY, 1, 1);
                  }
                  console.log(evt.buttons);
                }}
                onDoubleClick={(evt) => {
                  if (evt.buttons === 0) {
                    onMouseInput(evt.clientX, evt.clientY, 2, 0);
                  }
                  if (evt.buttons === 1) {
                    onMouseInput(evt.clientX, evt.clientY, 2, 1);
                  }
                  console.log(evt.buttons);
                }}
              >
                <img
                  id="remote-img"
                  className="w-96 h-64 bg-gray-200"
                  src={imgUrl}
                />
              </div>

              <div className="w-20 h-64 ml-5 flex flex-col">
                <div>
                  <div
                    className="text-base px-3 text-white mr-2"
                    style={{ backgroundColor: '#214E79' }}
                  >
                    chisu
                  </div>

                  <div className="text-white text-center">池御科技</div>
                </div>

                <div className="flex-1" />

                <div className="w-full h-1 bg-gray-300 rounded-full mb-3" />
                <div className="w-full h-1 bg-gray-300 rounded-full mb-3" />
                <div className="w-full h-1 bg-gray-300 rounded-full mb-3" />
              </div>
            </div>
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
