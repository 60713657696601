import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';
import * as F from '../../form';

import clsx from 'clsx';

export const NewT1 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);
  const [option3, setOption3] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });

    appCtx
      .fetchApi('get', '/api/v2/append-poll/parse-engine')
      .then((result) => {
        if (result) {
          setOption3(
            result.engines.map((item: any) => ({
              value: item.id,
              title: item.title,
            })),
          );
        }
      });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      v5: '',
      v6: '',
      v7: false,
      v8: false,
      v9: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.boolean(),
      v8: Yup.boolean(),
      v9: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll/poll-mid', {
        mids: [
          [
            values.v1,
            values.v2,
            values.v3,
            values.v4,
            values.v5,
            Number(values.v6),
            values.v7,
            values.v8,
            values.v9,
          ],
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="指令代號"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="Note"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="指令"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="回傳長度"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Checkbox
          label="指令含ID?"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Checkbox
          label="TX外加CRC?"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />
        <F.Select
          label="解析引擎"
          options={option3}
          value={formik.values.v9}
          onValueChange={(value) => formik.setFieldValue('v9', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewT2 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      v5: '',
      v6: '',
      v7: '',
      v8: '',
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
      v8: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll/poll-uid', {
        mid: 'p12345',
        rows: [
          [
            values.v1,
            values.v2,
            values.v3,
            values.v4,
            values.v5,
            values.v6,
            values.v7,
            values.v8,
          ],
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Input
          label="節點名稱"
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="單位"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="解析順序"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="數值格式"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="前偏移"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="後偏移"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="除數"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Input
          label="倍率"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const NewT3 = ({
  onOK,
  onCancel,
}: {
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: '',
      v2: '',
      v3: '',
      v4: '',
      v5: '',
      v6: '',
      v7: false,
      v8: false,
      v9: false,
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.boolean(),
      v8: Yup.boolean(),
      v9: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('post', '/api/v2/append-poll/write-mid', {
        mids: [
          [
            values.v1,
            values.v2,
            values.v3,
            values.v4,
            values.v5,
            values.v6,
            values.v7,
            values.v8,
            values.v9,
          ],
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('New')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="指令代號"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="Note"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="指令"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="Rx成功封包"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Checkbox
          label="指令含ID?"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Checkbox
          label="TX外加CRC?"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />
        <F.Checkbox
          label="Rx外加CRC?"
          value={formik.values.v9}
          onValueChange={(value) => formik.setFieldValue('v9', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT1 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);
  const [option3, setOption3] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });

    appCtx
      .fetchApi('get', '/api/v2/append-poll/parse-engine')
      .then((result) => {
        if (result) {
          setOption3(
            result.engines.map((item: any) => ({
              value: item.id,
              title: item.title,
            })),
          );
        }
      });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
      v5: item.value[4],
      v6: item.value[5].toString(),
      v7: item.value[6],
      v8: item.value[7],
      v9: item.value[8],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.boolean(),
      v8: Yup.boolean(),
      v9: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll/poll-mid', {
        mids: [
          {
            mid: item.mid,
            row: [
              values.v1,
              values.v2,
              values.v3,
              values.v4,
              values.v5,
              Number(values.v6),
              values.v7,
              values.v8,
              values.v9,
            ],
          },
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="指令代號"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="Note"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="指令"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="回傳長度"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Checkbox
          label="指令含ID?"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Checkbox
          label="TX外加CRC?"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />
        <F.Select
          label="解析引擎"
          options={option3}
          value={formik.values.v9}
          onValueChange={(value) => formik.setFieldValue('v9', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT2 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {}, []);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
      v5: item.value[4],
      v6: item.value[5],
      v7: item.value[6],
      v8: item.value[7],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.string().required(t('Required')),
      v8: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll/poll-uid', {
        mid: 'p12345',
        uids: [
          {
            uid: item.uid,
            row: [
              values.v1,
              values.v2,
              values.v3,
              values.v4,
              values.v5,
              values.v6,
              values.v7,
              values.v8,
            ],
          },
        ],
      });
      onOK();
    },
  });

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Input
          label="節點名稱"
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Input
          label="單位"
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="解析順序"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="數值格式"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="前偏移"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="後偏移"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Input
          label="除數"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Input
          label="倍率"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};

export const EditT3 = ({
  item,
  onOK,
  onCancel,
}: {
  item: any;
  onOK: () => void;
  onCancel: () => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [r, setR] = React.useState<any>(null);
  const [option1, setOption1] = React.useState<any[]>([]);
  const [option2, setOption2] = React.useState<any[]>([]);

  React.useEffect(() => {
    appCtx.fetchApi('get', '/api/v2/device/brand-model').then((result) => {
      if (result) {
        setR(result);
      }
    });
  }, []);

  React.useEffect(() => {
    if (r) {
      setOption1(r.brands.map((item: any) => ({ title: item, value: item })));
    }
  }, [r]);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      v1: item.value[0],
      v2: item.value[1],
      v3: item.value[2],
      v4: item.value[3],
      v5: item.value[4],
      v6: item.value[5],
      v7: item.value[6],
      v8: item.value[7],
      v9: item.value[8],
    },
    validationSchema: Yup.object().shape({
      v1: Yup.string().required(t('Required')),
      v2: Yup.string().required(t('Required')),
      v3: Yup.string().required(t('Required')),
      v4: Yup.string().required(t('Required')),
      v5: Yup.string().required(t('Required')),
      v6: Yup.string().required(t('Required')),
      v7: Yup.boolean(),
      v8: Yup.boolean(),
      v9: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      await appCtx.fetchApi('patch', '/api/v2/append-poll/write-mid', {
        mids: [
          {
            mid: 'w123456',
            row: [
              values.v1,
              values.v2,
              values.v3,
              values.v4,
              values.v5,
              values.v6,
              values.v7,
              values.v8,
              values.v9,
            ],
          },
        ],
      });
      onOK();
    },
  });

  React.useEffect(() => {
    if (r && formik.values.v1) {
      setOption2(
        r[formik.values.v1].map((item: any) => ({
          title: item,
          value: item,
        })),
      );
    }
  }, [r, formik.values.v1]);

  return (
    <div>
      <div className="text-white text-center py-1 bg-bar">{t('Edit')}</div>

      <div className="p-3">
        <F.Select
          label="模組廠牌"
          options={option1}
          value={formik.values.v1}
          onValueChange={(value) => formik.setFieldValue('v1', value)}
        />
        <F.Select
          label="型號名稱"
          options={option2}
          value={formik.values.v2}
          onValueChange={(value) => formik.setFieldValue('v2', value)}
        />
        <F.Input
          label="指令代號"
          value={formik.values.v3}
          onValueChange={(value) => formik.setFieldValue('v3', value)}
        />
        <F.Input
          label="Note"
          value={formik.values.v4}
          onValueChange={(value) => formik.setFieldValue('v4', value)}
        />
        <F.Input
          label="指令"
          value={formik.values.v5}
          onValueChange={(value) => formik.setFieldValue('v5', value)}
        />
        <F.Input
          label="Rx成功封包"
          value={formik.values.v6}
          onValueChange={(value) => formik.setFieldValue('v6', value)}
        />
        <F.Checkbox
          label="指令含ID?"
          value={formik.values.v7}
          onValueChange={(value) => formik.setFieldValue('v7', value)}
        />
        <F.Checkbox
          label="TX外加CRC?"
          value={formik.values.v8}
          onValueChange={(value) => formik.setFieldValue('v8', value)}
        />
        <F.Checkbox
          label="Rx外加CRC?"
          value={formik.values.v9}
          onValueChange={(value) => formik.setFieldValue('v9', value)}
        />

        <div className="mt-5 flex justify-end space-x-2">
          <button
            className="px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none"
            onClick={onCancel}
          >
            <div className="text-white text-base">{t('Cancel')}</div>
          </button>
          <button
            className={clsx(
              'px-5 py-1 rounded bg-button hover:bg-button-hover focus:outline-none',
            )}
            onClick={() => formik.submitForm()}
          >
            <div className="text-white text-base">{t('Confirm')}</div>
          </button>
        </div>
      </div>
    </div>
  );
};
