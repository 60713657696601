import React from 'react';
import clsx from 'clsx';

export interface InputProps {
  className?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  type?: string;
}

export const Input = ({
  className,
  value,
  onValueChange,
  label,
  placeholder,
  type = 'text',
}: InputProps) => {
  return (
    <div className={className}>
      <label className="text-gray-500">{label}</label>

      <input
        className="w-full h-10 mt-2 px-3 bg-gray-200 text-gray-500 rounded-lg"
        value={value}
        onChange={(evt) => onValueChange && onValueChange(evt.target.value)}
        placeholder={placeholder}
        type={type}
      />
    </div>
  );
};
