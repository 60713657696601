// done

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from '../part1-1/_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';
import { SelectVendor } from '../_SelectVendor';
import { SelectStoreQuick } from '../_SelectStoreQuick';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [storeQuick, setStoreQuick] = React.useState<string>('');
  const [data, setData] = React.useState<any>(null);

  const [selectedFile, setSelectedFile] = React.useState<string>('');

  const [log, setLog] = React.useState<{
    logs?: { start: string; end: string };
    files?: string[];
  }>({
    logs: {
      start: '2020-10-23T16:00:00',
      end: '2020-10-30T16:00:00',
    },
    files: ['C:/xxx/xxxx/xx.txt', 'C:/xxx/xxxx/yy.txt'],
  });

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',
    },
    validationSchema: Yup.object().shape({
      // department: Yup.string().required(t('Required')),
      // store: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi('get', `/api/v2/dl-log/status?token=${'xxx'}`)
        .then((result) => {
          if (result) {
            setData(result);
          }
        });
    },
  });

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Log file')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <C.BlueLabel title={t('Select')} />
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          <C.BlueLabel title={t('店舖快選')} />
          <SelectStoreQuick
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          {/* <C.Button
            title={t('Read')}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          /> */}
          {/* {formik.values.store} */}
        </C.SidePanel>

        <C.ContentPanel>
          <div className="flex flex-col space-y-3" style={{ flex: 3 }}>
            <C.BlueLabel title={t('Select download type')} />

            <div className="flex items-center space-x-3 ml-5">
              <input
                type="checkbox"
                style={{ width: 20, height: 20 }}
                checked={!!log.logs}
                onChange={(e) => {
                  if (e.target.checked) {
                    setLog((prev) => ({
                      ...prev,
                      logs: {
                        start: moment().format('YYYY-MM-DD'),
                        end: moment().format('YYYY-MM-DD'),
                      },
                    }));
                  } else {
                    setLog((prev) => ({
                      ...prev,
                      logs: undefined,
                    }));
                  }
                }}
              />
              <div className="text-base">{t('log檔')}</div>
            </div>

            <div className="flex items-center space-x-3 ml-10 w-72">
              <div className="text-base">{t('Start date')}</div>
              <C.DatePicker
                className="flex-1"
                value={
                  log?.logs ? moment(log?.logs?.start).format('YYYY-MM-DD') : ''
                }
                onValueChange={(date) =>
                  setLog((prev) => ({
                    ...prev,
                    logs: {
                      start: moment(date).format('YYYY-MM-DD'),
                      end: prev.logs?.end ?? '',
                    },
                  }))
                }
              />
            </div>

            <div className="flex items-center space-x-3 ml-10 w-72">
              <div className="text-base">{t('Start date')}</div>
              <C.DatePicker
                className="flex-1"
                value={
                  log.logs ? moment(log?.logs?.end).format('YYYY-MM-DD') : ''
                }
                onValueChange={(date) =>
                  setLog((prev) => ({
                    ...prev,
                    logs: {
                      start: prev.logs?.start ?? '',
                      end: moment(date).format('YYYY-MM-DD'),
                    },
                  }))
                }
              />
            </div>

            <div className="flex items-center space-x-3 ml-5">
              <input
                type="checkbox"
                style={{ width: 20, height: 20 }}
                checked={!!log.files}
                onChange={(e) => {
                  if (e.target.checked) {
                    setLog((prev) => ({
                      ...prev,
                      files: [],
                    }));
                  } else {
                    setLog((prev) => ({
                      ...prev,
                      files: undefined,
                    }));
                  }
                }}
              />
              <div className="text-base">{t('Specify file')}</div>
            </div>

            {/* <div className="flex items-center space-x-3 ml-10 w-72"> */}
            <C.SelectableListWithAdd
              className="ml-10 w-72"
              data={log.files || []}
              onEdit={(value) => {
                setLog((prev) => ({
                  ...prev,
                  files: value,
                }));
              }}
            />
            {/* </div> */}

            <div className="flex-1 flex flex-col space-y-3 w-32 ml-10">
              <C.Button
                title={t('Download')}
                onClick={() => {
                  appCtx
                    .fetchApi('post', `/api/v2/dl-log/dl`, {
                      store_id: formik.values.department || formik.values.store,
                      ...log,
                    })
                    .then((result) => {
                      if (result) {
                        // setStoreQuick(result.stores[0]);
                      }
                    });
                }}
              />

              {/* <div className="text-button">準備...</div>
              <div className="text-button">{t('Upload')}中...</div>
              <div className="text-button">成功</div> */}
            </div>
          </div>
        </C.ContentPanel>
      </div>
    </div>
  );
};
