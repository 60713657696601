import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../AppContext';
import * as C from '../../components';

import { SelectPanel } from './_Select';
import { SelectDepartmentMap } from '../_SelectDepartmentMap';
import { SelectStore } from '../_SelectStore';

export default () => {
  const appCtx = React.useContext(AppContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [pageInfo, setPageInfo] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);

  const formik = useFormik({
    isInitialValid: false,
    initialValues: {
      department: '',
      store: '',

      durationDate: '',
    },
    validationSchema: Yup.object().shape({
      department: Yup.string().required(t('Required')),
      store: Yup.string().required(t('Required')),
      // durationDate: Yup.string().required(t('Required')),
    }),
    onSubmit: async (values) => {
      appCtx
        .fetchApi(
          'get',
          `/api/v2/pwr-compare/detail?depart=${values.department}&storeid=${values.store}&low-pf=80&timezone=${appCtx.getTimezone}`,
        )
        .then((result) => {
          if (result) {
            setData(result);
          }
        });
    },
  });

  React.useEffect(() => {
    // if (!appCtx.token) {
    //   history.push('/login');
    // } else {
    // }
  }, []);

  return (
    <div className="h-screen flex flex-col pt-24">
      <C.Header title={appCtx.config.title} account={appCtx.user?.name!} />
      <C.Breadcrumbs path={[t('Home')]} current={t('Electrical comparison')} />

      <div className="flex-1 flex flex-col md:flex-row">
        <C.SidePanel>
          <SelectDepartmentMap
            selectedDepartment={formik.values.department}
            setSelectedDepartment={(value) =>
              formik.setFieldValue('department', value)
            }
          />
          <SelectStore
            depart={formik.values.department}
            selectedStore={formik.values.store}
            setSelectedStore={(value) => formik.setFieldValue('store', value)}
          />
          <div className="h-3" />

          <C.Button
            title={t('Inquire')}
            onClick={() => formik.submitForm()}
            disabled={!formik.isValid}
          />
        </C.SidePanel>

        <C.ContentPanel fixedHeight>
          {data && (
            <ReflexContainer orientation="horizontal">
              <ReflexElement flex={2}>
                <div className="flex mb-3">
                  <C.BlueLabel title={t('Statistic chart')} />
                </div>

                <div className="mb-3 grid grid-cols-1 gap-10">
                  {data?.charts?.map((item: any) => (
                    <C.ChartEx chart={item} />
                  ))}
                </div>
                <div className="h-10" />
              </ReflexElement>

              {/* splitter */}
              <ReflexSplitter
                className="my-5"
                style={{
                  backgroundColor: 'rgb(104,144,177)',
                  border: 'none',
                  height: 1,
                }}
              />

              <ReflexElement flex={1}>
                <C.Table2
                  label={data?.low_pf_table?.title}
                  header={data?.low_pf_table?.header}
                  data={data?.low_pf_table?.data}
                  page_size={data?.table?.page_size}
                  filter_index={data?.table?.filter_index}
                />
              </ReflexElement>
            </ReflexContainer>
          )}
        </C.ContentPanel>
      </div>
    </div>
  );
};
