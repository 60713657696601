import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface SelectableListProps {
  className?: string;
  data?: Array<{ title: string; value: string }>;
  value: string;
  onValueChange?: (value: string) => void;
}

export const SelectableList = ({
  className,
  data,
  value,
  onValueChange,
}: SelectableListProps) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={clsx(
        'h-64 border border-gray-500 bg-white py-1 overflow-y-scroll',
        className,
      )}
    >
      {data?.map((item) => {
        return (
          <div
            className={clsx(
              'cursor-pointer px-2 py-1',
              value === item.value
                ? 'bg-bar text-white'
                : 'bg-white text-gray-500',
            )}
            onClick={() => {
              onValueChange && onValueChange(item.value);
            }}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
};
