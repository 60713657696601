import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../AppContext';
import * as C from '../components';

export const SelectStoreQuick = ({
  selectedStore,
  setSelectedStore,
}: {
  selectedStore: string;
  setSelectedStore: (value: string) => void;
}) => {
  const appCtx = React.useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [searchKey, setSearchKey] = React.useState<string>('');
  const [store, setStore] = React.useState<{ title: string; value: string }[]>(
    [],
  );

  React.useEffect(() => {
    if (appCtx.token && searchKey !== '') {
      appCtx
        .fetchApi('get', `/api/v2/store-quick?key=${searchKey}`)
        .then((result) => {
          if (result) {
            setStore(
              result.stores.map((item: any) => ({
                title: item[1],
                value: item[0],
              })),
            );
          }
        });
    }
  }, [appCtx.token, searchKey]);

  return (
    // <C.Select
    //   options={store}
    //   value={selectedStore}
    //   onValueChange={setSelectedStore}
    // />
    <>
      <input
        className="h-10 border border-gray-500 px-2 text-gray-500"
        name="store"
        list="store_list"
        placeholder="店舖快選"
        value={searchKey}
        onChange={(evt) => {
          setSearchKey(evt.target.value);
          setSelectedStore(evt.target.value);

          // if (store.map((item) => item.value).includes(evt.target.value)) {
          //   setSelectedStore(evt.target.value);
          //   console.log(evt.target.value);
          // } else {
          //   setSelectedStore('');
          //   console.log('no store');
          // }
        }}
      />
      <datalist id="store_list">
        {store.map((item, index) => (
          <option key={index} value={item.value}>
            {item.title}
          </option>
        ))}
      </datalist>
    </>
  );
};
